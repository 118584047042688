import {
    Box,
    Button,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import {
    collection,
    deleteDoc,
    doc,
    getDocs,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../services/firebaseService";
import OrganizationSelect from "../OrganizationSelect/OrganizationSelect";
import { ADMIN_CONFIG_PATH } from "../../paths";

interface BlogConfig {
    uid: string;
    name: string;
    is_active: string;
    type_of_llm: string;
}

interface AdminConfigManagementRootProps {
    selectedOrganization: string | undefined;
    setSelectedOrganization: React.Dispatch<React.SetStateAction<string>>;
}

const AdminConfigManagementRoot: React.FC<AdminConfigManagementRootProps> = ({
    selectedOrganization,
    setSelectedOrganization,
}) => {
    const { currentUser, userRole } = useAuth();
    const [blogConfigData, setBlogConfigData] = useState<BlogConfig[] | null>(
        null
    );
    const [blogConfigCount, setblogConfigCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setBlogConfigData([]); // Clear the existing blog data

            if (currentUser && selectedOrganization) {
                try {
                    // Reference to the selected company's blog configs
                    const blogConfigsCollectionRef = collection(
                        db,
                        ADMIN_CONFIG_PATH,
                        selectedOrganization,
                        `blog`
                    );
                    const blogConfigsSnapshot = await getDocs(
                        blogConfigsCollectionRef
                    );
                    const blogConfigsData: BlogConfig[] =
                        blogConfigsSnapshot.docs.map((doc) => ({
                            uid: doc.id,
                            name: doc.data().name,
                            is_active: doc.data().is_active,
                            type_of_llm: doc.data().type_of_llm
                        }));
                    setBlogConfigData(blogConfigsData);
                    setblogConfigCount(blogConfigsData.length);
                } catch (error) {
                    console.error("Error fetching blogs:", error);
                }
            }

            setLoading(false);
        };

        fetchData();
    }, [currentUser, selectedOrganization]);

    const handleCreateConfig = () => {
        navigate(`/new/admin-config/${selectedOrganization}`, {
            state: { organizationUid: selectedOrganization },
        });
    };

    const handleUpdateConfig = async (config: BlogConfig) => {
        navigate(`/edit/admin-config/${selectedOrganization}/${config.uid}`, {
            state: { organizationUid: selectedOrganization },
        });
    };

    const handleDeleteBlogConfig = async (config: BlogConfig) => {
        if (!window.confirm("このブログ構成を削除してもよろしいですか？")) {
            return;
        }

        try {
            const orgDocRef = doc(
                db,
                ADMIN_CONFIG_PATH,
                selectedOrganization!,
                "/blog",
                config.uid
            );
            await deleteDoc(orgDocRef);

            // Remove the deleted organization from blogConfigData
            const updatedBlogConfigData = blogConfigData!.filter(
                (item) => item.uid !== config.uid
            );
            setBlogConfigData(updatedBlogConfigData);

            // Decrement the count by 1
            setblogConfigCount(blogConfigCount - 1);

            setSnackbarMessage("ブログ構成を正常に削除しました");
            setSnackbarOpen(true);
        } catch (error) {
            console.error("ブログ構成の削除中にエラーが発生しました：", error);
        }
    };

    return (
        <>
        <OrganizationSelect
            selectedOrganization={selectedOrganization}
            setSelectedOrganization={setSelectedOrganization}
        />
        <Box p={2}>
            <Box my={2}>
                {selectedOrganization && blogConfigCount === 0 && (
                    <>
                        <Typography variant="subtitle1" gutterBottom>
                            管理構成が作成されていません
                        </Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleCreateConfig}
                            disabled={userRole !== "root" || loading}
                        >
                            構成作成{/* Create New Configuration */}
                        </Button>
                    </>
                )}
                {selectedOrganization && blogConfigCount > 0 && (
                    <>
                        <Box mb={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleCreateConfig}
                                disabled={userRole !== "root" || loading}
                            >
                                ブログ作成{/* Create New BlogConfig */}
                            </Button>
                        </Box>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>名前</TableCell> {/* Name */}
                                        <TableCell>有効可否</TableCell>{" "}
                                        <TableCell>
                                            言語モデルのタイプ
                                        </TableCell>{" "}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {blogConfigData!.map((blogConfig) => (
                                        <TableRow key={blogConfig.name}>
                                            <TableCell>
                                                {blogConfig.name}
                                            </TableCell>
                                            <TableCell>
                                                {blogConfig.is_active ? '有効' : '無効'}
                                            </TableCell>
                                            <TableCell>
                                                {blogConfig.type_of_llm}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Box pr={1} component="span">
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() =>
                                                            handleUpdateConfig(
                                                                blogConfig
                                                            )
                                                        }
                                                        disabled={
                                                            userRole !== "root"
                                                        }
                                                    >
                                                        編集 {/* Edit */}
                                                    </Button>
                                                </Box>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={() =>
                                                        handleDeleteBlogConfig(
                                                            blogConfig
                                                        )
                                                    }
                                                    disabled={
                                                        userRole !== "root"
                                                    }
                                                >
                                                    削除 {/* Delete */}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            />
        </Box>
        </>
    );
};

export default AdminConfigManagementRoot;
