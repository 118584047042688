import React, {
    createContext,
    useContext,
    ReactNode,
    useState,
    useEffect,
} from "react";
import { auth, db, doc, getDoc } from "../services/firebaseService";
import {
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    onAuthStateChanged,
    User,
    GoogleAuthProvider,
    signInWithPopup,
    signInWithPhoneNumber,
    RecaptchaVerifier,
    ConfirmationResult,
    deleteUser,
    getAdditionalUserInfo,
} from "firebase/auth";
import { translateFirebaseError } from "../utils/firebaseErrorLocalizer";
import { Organization } from "../types/organizations/organizations";
import { USERS_PATH, FIREBASE_AUTHENTICATORS_PATH } from "../paths";

interface AuthContextType {
    login: (email: string, password: string) => Promise<void>;
    googleLogin: () => Promise<void>;
    logout: () => Promise<void>;
    sendResetEmail: (email: string) => Promise<void>;
    currentUser: User | null;
    phoneLogin: (
        phoneNumber: string,
        recaptchaVerifier: RecaptchaVerifier
    ) => Promise<ConfirmationResult>;
    userRole: string | null;
    organization: Organization | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [userRole, setUserRole] = useState<string | null>(null);
    const [organization, setOrganization] = useState<Organization | null>(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (currentUser) {
            const fetchUserRole = async () => {
                try {
                    const userDocRef = doc(
                        db,
                        `${USERS_PATH}/${currentUser.uid}`
                    );
                    const userDocSnap = await getDoc(userDocRef);
                    if (userDocSnap.exists()) {
                        const role = userDocSnap.data()?.role;
                        setUserRole(role || null);
                    }
                } catch (error) {
                    console.error("Error fetching user role:", error);
                }
            };

            fetchUserRole();
        }
    }, [currentUser]);

    useEffect(() => {
        const fetchOrganizationData = async () => {
            if (currentUser) {
                const authRef = doc(
                    db,
                    `${FIREBASE_AUTHENTICATORS_PATH}/${currentUser.uid}`
                );
                const authSnap = await getDoc(authRef);
                if (authSnap.exists() && authSnap.data()?.organization_uid) {
                    const orgRef = authSnap.data().organization_uid;
                    const orgSnap = await getDoc(orgRef);
                    if (orgSnap.exists()) {
                        setOrganization(orgSnap.data() as Organization);
                    } else {
                        console.log("No such document for organization!");
                    }
                } else {
                    console.log(
                        "No data for user in authenticators table:",
                        currentUser.uid
                    );
                }
            }
        };

        fetchOrganizationData();
    }, [currentUser]);

    const login = async (email: string, password: string) => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            const errorMessage = translateFirebaseError(error, "signin");
            throw new Error(errorMessage);
        }
    };

    const googleLogin = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const allowedDomains = ["nicosys.jp"];
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            //FIX-ME once firebase admin SDK is implemented please erase this hacky solution and prevent sign-up using the Admin SDK
            const isNewUser = getAdditionalUserInfo(result)?.isNewUser;

            if (isNewUser) {
                if (user.email) {
                    const emailDomain = user.email.split("@")[1];
                    if (!allowedDomains.includes(emailDomain)) {
                        await deleteUser(user);
                        throw new Error("新しいユーザは許可されていません。");
                    }
                }
            }

            if (user.email) {
                const emailDomain = user.email.split("@")[1];
                if (!allowedDomains.includes(emailDomain)) {
                    throw new Error(
                        "このドメインからのログインは許可されていません。"
                    );
                }
            }
        } catch (error) {
            const errorMessage = translateFirebaseError(error, "googlelogin");
            throw new Error(errorMessage);
        }
    };

    const phoneLogin = async (
        phoneNumber: string,
        recaptchaVerifier: RecaptchaVerifier
    ): Promise<ConfirmationResult> => {
        try {
            if (!recaptchaVerifier) {
                throw new Error(
                    "ReCAPTCHA検証器のインスタンスが指定されていません。"
                );
            }
            const confirmationResult = await signInWithPhoneNumber(
                auth,
                phoneNumber,
                recaptchaVerifier
            );
            return confirmationResult;
        } catch (error) {
            console.error("Error during phone authentication:", error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            const errorMessage = translateFirebaseError(error, "signout");
            throw new Error(errorMessage);
        }
    };

    const sendResetEmail = async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email);
        } catch (error) {
            const errorMessage = translateFirebaseError(error, "resetpassword");
            throw new Error(errorMessage);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                login,
                googleLogin,
                logout,
                sendResetEmail,
                currentUser,
                phoneLogin,
                userRole,
                organization,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
