import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";
import { AppBar, Avatar, Box, Menu, Popover, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Snackbar from "@mui/material/Snackbar";
import Toolbar from "@mui/material/Toolbar";
import { sendPasswordResetEmail, updateProfile } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, {
    ChangeEvent,
    useEffect,
    useRef,
    useState
} from "react";
import { Link, useNavigate } from "react-router-dom";
import logoDark from "../../assets/bebuddy_logo.png";
import logo from "../../assets/bebuddy_white_logo.png";
import { useAuth } from "../../contexts/AuthContext";
import { auth, db, storage } from "../../services/firebaseService";
import HamburgerIcon from "./HamburgerIcon";
import { ORGANIZATIONS_PATH } from "../../paths";

type ThemeTypes = "light" | "dark";
interface NavbarProps {
    selectedTheme: ThemeTypes;
    selectedCompanyLogo: string;
}

const Navbar: React.FC<NavbarProps> = ({
    selectedTheme,
    selectedCompanyLogo,
}) => {
    const { currentUser, userRole, logout, organization } = useAuth();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [resetPasswordSnackbarOpen, setResetPasswordSnackbarOpen] =
        useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [currentTitle, setCurrentTitle] = useState("設定");

    const handleTitleChange = (newTitle: string) => {
        setCurrentTitle(newTitle);
    };
    const [companyLogo, setCompanyLogo] = useState("");
    const fileInputRef = useRef<File | null>(null);

    const navigate = useNavigate();

    // State for the submenu
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    // State for the popover (upload image)
    const [popoverAnchorEl, setPopoverAnchorEl] =
        React.useState<HTMLElement | null>(null);

    // Function to open the submenu
    const handleSubMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // Function to close the submenu
    const handleSubMenuClose = () => {
        setAnchorEl(null);
    };

    // Function to open the popover (upload image)
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setPopoverAnchorEl(event.currentTarget);
    };

    // Function to close the popover (upload image)
    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    };

    useEffect(() => {
        const fetchLogo = async () => {
            if (currentUser && organization) {
                const orgRef = doc(
                    db,
                    `${ORGANIZATIONS_PATH}/${organization.uid}`
                );
                try {
                    const orgData = await getDoc(orgRef);
                    if (orgData.exists()) {
                        const logoPath = orgData.data().logo; // Assuming logo path is stored in Firestore
                        if (logoPath !== "") {
                            const storageRef = ref(storage, logoPath); // Reference to the logo in Firebase Storage
                            // Try to get the download URL for the logo
                            try {
                                const logoURL = await getDownloadURL(
                                    storageRef
                                );
                                setCompanyLogo(logoURL);
                            } catch (error) {
                                console.error("Error fetching logo:", error);
                                // Set the default logo when fetching the custom logo fails
                                setCompanyLogo(logo); // Setting the default logo when fetching fails
                            }
                        } else {
                            setCompanyLogo(logo);
                        }
                    } else {
                        console.error("Organization document doesn't exist");
                        // Set the default logo when the organization document doesn't exist
                        setCompanyLogo(logo); // Setting the default logo when the organization document doesn't exist
                    }
                } catch (error) {
                    console.error("Error fetching organization:", error);
                    // Set the default logo in case of an error while fetching the organization data
                    setCompanyLogo(logo); // Setting the default logo in case of an error
                }
            }
        };

        fetchLogo();
    }, [currentUser, organization, selectedCompanyLogo]);

    const handleEdit = () => {
        setAnchorEl(null);
        if (currentUser && organization) {
            navigate(`/edit/${currentUser.uid}`, {
                state: { organizationUid: organization.uid },
            });
        }
    };

    const handlePasswordResetConfirmation = () => {
        handlePasswordReset();
        setResetPasswordSnackbarOpen(false);
    };

    const handlePasswordReset = async () => {
        if (!currentUser?.email) return;

        try {
            await sendPasswordResetEmail(auth, currentUser.email);
            setSnackbarMessage(
                "パスワードリセットメールが正常に送信されました。"
            ); // Set the message for the Snackbar
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Error sending password reset email:", error);
            setSnackbarMessage(
                "パスワードリセットメールの送信中にエラーが発生しました。"
            ); // Set an error message for the Snackbar
            setSnackbarOpen(true);
        }
    };

    const handleLogout = async () => {
        try {
            await logout();
        } catch (error) {
            setSnackbarMessage("ログアウトに失敗しました" + error);
            setSnackbarOpen(true);
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            fileInputRef.current = selectedFile;
            handlePictureUpload();
        }
    };

    const handlePictureUpload = async () => {
        if (currentUser && organization) {
            if (fileInputRef.current) {
                // Upload the selected image to Firebase Storage
                const storageRef = ref(
                    storage,
                    `users/${currentUser?.uid}/profile-pic.png`
                );
                try {
                    await uploadBytes(storageRef, fileInputRef.current);
                    // Get the download URL for the uploaded image
                    const photoURL = await getDownloadURL(storageRef);
                    // Update the user's profile with the new photoURL
                    await updateProfile(currentUser, { photoURL });
                    // Refresh the user data
                    await currentUser.reload();

                    setSnackbarMessage("Succesfully uploaded logo!");
                    setSnackbarOpen(true);
                } catch (error) {
                    console.error("Error uploading profile picture:", error);
                    // Handle the error (e.g., show a snackbar)
                }
            }
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AppBar position="static">
                <Toolbar sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setDrawerOpen(true)}
                    >
                        <HamburgerIcon open={drawerOpen} />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginRight: "auto" }}>
                        {currentTitle}
                    </Typography>
                    <img
                        src={companyLogo}
                        alt="Company Logo"
                        style={{
                            maxWidth: "8.6rem",
                            maxHeight: "5rem",
                            marginRight: "1.6rem", // Adjust as needed
                        }}
                    />
                    {currentUser && (
                        <Box>
                            <Avatar
                                alt="User Photo"
                                src={currentUser.photoURL!}
                                onClick={handleSubMenuOpen}
                                aria-controls="user-menu"
                                aria-haspopup="true"
                                sx={{ cursor: "pointer" }}
                            />
                            <Menu
                                id="user-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleSubMenuClose}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-around"
                                    marginLeft={"35px"}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="stretch"
                                        justifyContent="space-between"
                                        marginBottom={"10px"}
                                    >
                                        <Avatar
                                            alt="User Photo"
                                            src={currentUser.photoURL!}
                                            sx={{
                                                width: "6rem",
                                                height: "6rem",
                                                position: "relative",
                                            }}
                                        ></Avatar>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        <input
                                            accept="image/png, image/jpeg, image/jpg"
                                            id="profile-pic"
                                            type="file"
                                            onChange={handleFileChange}
                                            style={{ display: "none" }}
                                        />
                                        <label
                                            htmlFor="profile-pic"
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            <IconButton
                                                sx={{
                                                    top: "1rem", // Adjust the position as needed
                                                    right: "2rem", // Adjust the position as needed
                                                    background: "white",
                                                    color: "black",
                                                }}
                                                onClick={handlePopoverOpen}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </label>
                                    </Box>

                                    <Box
                                        sx={{
                                            paddingLeft: "10px",
                                        }}
                                    >
                                        <Typography variant="h5" gutterBottom>
                                            ユーザ情報
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            gutterBottom
                                            sx={{
                                                width: "17rem",
                                                overflowWrap: "break-word",
                                            }}
                                        >
                                            メールアドレス:{" "}
                                            {currentUser
                                                ? currentUser.email
                                                : "ゲスト"}
                                        </Typography>
                                        {organization && (
                                            <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                                sx={{
                                                    width: "17rem",
                                                    overflowWrap: "break-word",
                                                }}
                                            >
                                                所属する組織:{" "}
                                                {organization.name}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    marginLeft={"10px"}
                                >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleEdit}
                                    >
                                        編集
                                    </Button>
                                    <Box pl={1}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() =>
                                                setResetPasswordSnackbarOpen(
                                                    true
                                                )
                                            }
                                        >
                                            パスワードをリセット
                                        </Button>
                                    </Box>
                                    <Box
                                        pl={1}
                                        sx={{
                                            marginRight: "10px",
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={handleLogout}
                                            color="info"
                                        >
                                            ログアウト
                                        </Button>
                                    </Box>
                                </Box>
                            </Menu>
                            <Popover
                                id="upload-popover"
                                open={Boolean(popoverAnchorEl)}
                                anchorEl={popoverAnchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                            >
                                <Box p={2}>
                                    <input
                                        accept="image/png"
                                        id="profile-pic"
                                        type="file"
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                    />
                                    <label htmlFor="profile-pic">
                                        <Button
                                            variant="outlined"
                                            component="span"
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            画像をアップロード
                                        </Button>
                                    </label>
                                </Box>
                            </Popover>
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <List>
                    <ListItem>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                                src={selectedTheme === "dark" ? logo : logoDark}
                                alt="Logo"
                                style={{
                                    height: "50px",
                                    marginRight: "10px",
                                }}
                            />
                            <div
                                style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    color:
                                        selectedTheme === "dark"
                                            ? "#f57c00"
                                            : "#3f51b5",
                                    cursor: "pointer",
                                }}
                            >
                                Be:Buddy
                            </div>
                        </Box>
                    </ListItem>
                    <Divider />
                    {currentUser && (
                        <>
                            {(userRole === "root" || userRole === "admin") && (
                            <ListItem>
                                <Button
                                    variant="text"
                                    color="inherit"
                                    component={Link}
                                    to="/user-management"
                                    onClick={() => {
                                        handleTitleChange("ユーザ管理");
                                        setDrawerOpen(false);
                                    }}
                                >
                                    ユーザ管理
                                </Button>
                            </ListItem>
                            )}
                            <ListItem>
                                <Button
                                    variant="text"
                                    color="inherit"
                                    component={Link}
                                    to="/org-management"
                                    onClick={() => {
                                        handleTitleChange("組織");
                                        setDrawerOpen(false);
                                    }}
                                >
                                    組織
                                </Button>
                            </ListItem>
                            {(userRole === "root" || userRole === "admin") && (
                                <ListItem>
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        component={Link}
                                        to="/config-management"
                                        onClick={() => {
                                            handleTitleChange("システム設定");
                                            setDrawerOpen(false);
                                        }}
                                    >
                                        システム設定
                                    </Button>
                                </ListItem>
                            )}
                            {(userRole === "root" || userRole === "admin") && (
                                <ListItem>
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        component={Link}
                                        to="/secret-management"
                                        onClick={() => {
                                            handleTitleChange("秘匿情報");
                                            setDrawerOpen(false);
                                        }}
                                    >
                                        秘匿情報
                                    </Button>
                                </ListItem>
                            )}
                            {userRole === "root" && (
                                <ListItem>
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        component={Link}
                                        to="/admin-config-management"
                                        onClick={() => {
                                            handleTitleChange("管理構成");
                                            setDrawerOpen(false);
                                        }}
                                    >
                                        管理構成
                                    </Button>
                                </ListItem>
                            )}
                            {(userRole === "root" || userRole === "admin") && (
                                <ListItem>
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        component={Link}
                                        to="/post-management"
                                        onClick={() => {
                                            handleTitleChange("投稿管理");
                                            setDrawerOpen(false);
                                        }}
                                    >
                                        投稿管理
                                    </Button>
                                </ListItem>
                            )}
                            {(userRole === "root" || userRole === "admin") && (
                                <ListItem>
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        component={Link}
                                        to="/post-log-management"
                                        onClick={() => {
                                            handleTitleChange("投稿ログ管理");
                                            setDrawerOpen(false);
                                        }}
                                    >
                                        投稿ログ管理
                                    </Button>
                                </ListItem>
                            )}
                            <ListItem>
                                <Button
                                    variant="text"
                                    color="inherit"
                                    component={Link}
                                    to="/settings"
                                    onClick={() => {
                                        handleTitleChange("設定");
                                        setDrawerOpen(false);
                                    }}
                                >
                                    設定
                                </Button>
                            </ListItem>
                        </>
                    )}
                </List>
            </Drawer>
            {/* Other components like Snackbar */}
            <Snackbar
                open={resetPasswordSnackbarOpen}
                onClose={() => setResetPasswordSnackbarOpen(false)}
                message="パスワードをリセットしますか？"
                action={
                    <>
                        <Button
                            color="inherit"
                            onClick={handlePasswordResetConfirmation}
                        >
                            確認
                        </Button>
                        <IconButton
                            size="small"
                            color="inherit"
                            onClick={() => setResetPasswordSnackbarOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                }
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </>
    );
};

export default Navbar;
