import { CssBaseline, ThemeProvider } from "@mui/material";
import React, { useState } from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import AdminConfigForm from "./components/Admin-Configurations/AdminConfigForm";
import ConfigForm from "./components/Configurations/ConfigForm";
import MainLayout from "./components/MainLayout";
import OrgForm from "./components/Organizations/OrgForm";
import PrivateRoute from "./components/PrivateRoute";
import SecretForm from "./components/Secrets/SecretForm";
import UserForm from "./components/Users/UserForm";
import { AuthProvider } from "./contexts/AuthContext";
import AdminConfigManagement from "./pages/AdminConfigManagement";
import ConfigManagement from "./pages/ConfigManagement";
import Login from "./pages/Login";
import OrgManagement from "./pages/OrgManagement";
import SecretManagement from "./pages/SecretManagement";
import Settings from "./pages/Settings";
import UserManagement from "./pages/UserManagement";
import PostManagement from "./pages/PostManagement";
import PostDetails from "./components/Posts/PostDetails";
import PostLogManagement from "./pages/PostLogManagement";
import { lightTheme } from "./styles/lightTheme";
import { darkTheme } from "./styles/darkTheme";

const UserFormWithParams: React.FC = () => {
    const { userId } = useParams();

    return <UserForm mode="EDIT" uid={userId} />;
};

interface OrgFormWithParamsProps {
    handleLogoChange: (logo: string) => void;
}

const OrgFormWithParams: React.FC<OrgFormWithParamsProps> = ({
    handleLogoChange,
}) => {
    const { orgId } = useParams();

    return (
        <OrgForm mode="EDIT" uid={orgId} handleLogoChange={handleLogoChange} />
    );
};

const ConfigFormWithParams: React.FC = () => {
    const { orgId } = useParams();

    return <ConfigForm uid={orgId} />;
};

const AdminConfigFormWithParams: React.FC = () => {
    const { orgId } = useParams();

    return <AdminConfigForm mode="REGISTER" uid={orgId} />;
};

const AdminConfigEditFormWithParams: React.FC = () => {
    const { orgId, configId } = useParams();

    return <AdminConfigForm mode="EDIT" uid={orgId} blog_id={configId} />;
};

const ChannelRegisterFormWithParams: React.FC = () => {
    const { channel_type, orgId } = useParams();

    return (
        <SecretForm
            mode="REGISTER"
            channel_type={
                channel_type === "Slack" || channel_type === "Teams"
                    ? channel_type
                    : undefined
            }
            uid={orgId}
        />
    );
};

const ChannelEditFormWithParams: React.FC = () => {
    const { orgId, channel_type, channel_id } = useParams();

    return (
        <SecretForm
            mode="EDIT"
            channel_type={
                channel_type === "Slack" || channel_type === "Teams"
                    ? channel_type
                    : undefined
            }
            uid={orgId}
            channel_id={channel_id}
        />
    );
};

const UserManagementWithParams: React.FC = () => {
    const { orgId } = useParams();

    return <UserManagement uid={orgId} />;
};

const ConfigManagementWithParams: React.FC = () => {
    const { orgId } = useParams();

    return <ConfigManagement uid={orgId} />;
};

const SecretManagementWithParams: React.FC = () => {
    const { orgId } = useParams();

    return <SecretManagement uid={orgId} />;
};

const AdminConfigManagementWithParams: React.FC = () => {
    const { orgId } = useParams();

    return <AdminConfigManagement uid={orgId} />;
};

type ThemeTypes = "light" | "dark";

function App() {
    const themes: Record<ThemeTypes, any> = {
        light: lightTheme,
        dark: darkTheme,
    };

    const [selectedTheme, setSelectedTheme] = useState<ThemeTypes>("dark");
    const [companyLogo, setCompanyLogo] = useState<string>(""); // State for the company logo

    const handleThemeChange = (theme: ThemeTypes) => {
        setSelectedTheme(theme);
    };

    const handleLogoChange = (logo: string) => {
        setCompanyLogo(logo);
    };

    return (
        <div className="App">
            <ThemeProvider theme={themes[selectedTheme]}>
                <CssBaseline />
                <AuthProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/" element={<PrivateRoute />}>
                                <Route
                                    element={
                                        <MainLayout
                                            selectedTheme={selectedTheme}
                                            companyLogo={companyLogo}
                                        />
                                    }
                                >
                                    <Route
                                        index
                                        element={
                                            <Settings
                                                currentTheme={selectedTheme}
                                                handleThemeChange={
                                                    handleThemeChange
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="settings"
                                        element={
                                            <Settings
                                                currentTheme={selectedTheme}
                                                handleThemeChange={
                                                    handleThemeChange
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="signup"
                                        element={<UserForm mode="REGISTER" />}
                                    />
                                    <Route
                                        path="user-management"
                                        element={
                                            <UserManagement uid={undefined} />
                                        }
                                    />
                                    <Route
                                        path="user-management/:orgId"
                                        element={<UserManagementWithParams />}
                                    />
                                    <Route
                                        path="/edit/:userId"
                                        element={<UserFormWithParams />}
                                    />
                                    <Route
                                        path="/new/org"
                                        element={
                                            <OrgForm
                                                mode="REGISTER"
                                                handleLogoChange={
                                                    handleLogoChange
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="org-management"
                                        element={
                                            <OrgManagement
                                                handleLogoChange={
                                                    handleLogoChange
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/edit/org/:orgId"
                                        element={
                                            <OrgFormWithParams
                                                handleLogoChange={
                                                    handleLogoChange
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/new/config/:orgId"
                                        element={<ConfigFormWithParams />}
                                    />
                                    <Route
                                        path="config-management"
                                        element={<ConfigManagement />}
                                    />
                                    <Route
                                        path="config-management/:orgId"
                                        element={<ConfigManagementWithParams />}
                                    />
                                    <Route
                                        path="secret-management"
                                        element={<SecretManagement />}
                                    />
                                    <Route
                                        path="secret-management/:orgId"
                                        element={<SecretManagementWithParams />}
                                    />
                                    <Route
                                        path="/new/admin-config/:orgId"
                                        element={<AdminConfigFormWithParams />}
                                    />
                                    <Route
                                        path="admin-config-management"
                                        element={<AdminConfigManagement />}
                                    />
                                    <Route
                                        path="admin-config-management/:orgId"
                                        element={
                                            <AdminConfigManagementWithParams />
                                        }
                                    />
                                    <Route
                                        path="/edit/admin-config/:orgId/:configId"
                                        element={
                                            <AdminConfigEditFormWithParams />
                                        }
                                    />
                                    <Route
                                        path="/new/:channel_type/:orgId"
                                        element={
                                            <ChannelRegisterFormWithParams />
                                        }
                                    />
                                    <Route
                                        path="/edit/:channel_type/:orgId/:channel_id"
                                        element={<ChannelEditFormWithParams />}
                                    />
                                    <Route
                                        path="post-management"
                                        element={<PostManagement />}
                                    />
                                    <Route
                                        path="/post-management/:orgId/:authorId/:postId"
                                        element={<PostDetails />}
                                    />
                                    <Route
                                        path="post-log-management"
                                        element={<PostLogManagement />}
                                    />
                                </Route>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </AuthProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
