import { Theme, ThemeOptions, createTheme } from "@mui/material/styles";
import { jaJP } from "@mui/material/locale";
import { baseThemeProps } from "./baseTheme";

const darkThemeProps = {
    palette: {
        mode: "dark",
        primary: {
            main: "#f57c00", //orange
        },
        secondary: {
            main: "#29b6f6", //cyan
        },
        info: {
            main: "#ffffff", //white
        },
    },
    ...baseThemeProps
}

export const darkTheme: Theme = createTheme(darkThemeProps as ThemeOptions, jaJP);