const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function encryptWithKMS(plainText: string, idToken: string) {
    return fetch(`${BASE_URL}/auth/encode`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ plainText: plainText }),
    })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((errorData) => {
                    throw new Error(`API Error: ${errorData.message}`);
                });
            }
            return response.json();
        })
        .catch((error) => {
            console.error("Error:", error);
            throw error; // Re-throw the error for further handling
        });
}

export function decryptWithKMS(ciphertext: string, idToken: string) {
    return fetch(`${BASE_URL}/auth/decode`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ ciphertext: ciphertext }),
    })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((errorData) => {
                    throw new Error(`API Error: ${errorData.message}`);
                });
            }
            return response.json();
        })
        .catch((error) => {
            console.error("Error:", error);
            throw error; // Re-throw the error for further handling
        });
}
