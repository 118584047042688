const firebaseErrorMessages: any = {
    "auth/app-deleted": "このアプリは存在しないか、削除されました。",
    "auth/expired-action-code": "このアクションコードは有効期限が切れました。",
    "auth/invalid-action-code": "このアクションコードは無効です。",
    "auth/user-disabled": "アカウントは無効になっています。",
    "auth/user-not-found": "ユーザが見つかりませんでした。",
    "auth/weak-password": "パスワードは6文字以上にしてください。",
    "auth/email-already-in-use": "このメールアドレスは既に使用されています。",
    "auth/invalid-email": "メールアドレスの形式が正しくありません。",
    "auth/operation-not-allowed": "現在この認証方法は使用できません。",
    "auth/account-exists-with-different-credential":
        "異なる認証方法で既にアカウントが存在します。",
    "auth/auth-domain-config-required": "認証ドメインの設定が必要です。",
    "auth/credential-already-in-use": "この認証情報は既に使用されています。",
    "auth/operation-not-supported-in-this-environment":
        "現在この環境ではサポートされていません。",
    "auth/popup-blocked":
        "認証ポップアップがブロックされました。ポップアップブロックを無効にしてください。",
    "auth/popup-closed-by-user": null,
    "auth/unauthorized-domain": "現在この認証方法は使用できません。",
    "auth/requires-recent-login": "認証の有効期限が切れています。",
    "auth/wrong-password": "メールアドレスまたはパスワードが正しくありません。",
    "auth/missing-continue-uri": "継続のURIが必要です。",
    "auth/missing-ios-bundle-id": "iOSのバンドルIDが不足しています。",
    "auth/invalid-continue-uri": "継続のURIが無効です。",
    "auth/unauthorized-continue-uri": "認証されていない継続のURIです。",
    "auth/invalid-dynamic-link-domain":
        "無効なダイナミックリンクのドメインです。",
    "auth/argument-error": "不正な引数が提供されました。",
    "auth/invalid-persistence-type": "無効な持続性タイプです。",
    "auth/unsupported-persistence-type":
        "サポートされていない持続性タイプです。",
    "auth/invalid-cordova-configuration": "Cordovaの設定が無効です。",
    "auth/missing-email": "メールアドレスを入力してください。",
};

function translateFirebaseError(error: any, method?: string): string {
    const message = firebaseErrorMessages[error.code];

    if (message !== undefined && message !== null) {
        return message;
    }

    if (method && method.indexOf("signin") !== -1) {
        return "認証に失敗しました。しばらく時間をおいて再試行してください。";
    }
    //when you see new error messages here please add japanese translations to the list above
    return "エラーが発生しました: " + error.message;
}

export { translateFirebaseError };
