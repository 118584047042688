import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import PostLogManagementAdmin from "../components/PostLog/PostLogManagementAdmin";
import OrganizationSelect from "../components/OrganizationSelect/OrganizationSelect";
import { fetchUserOrganization } from "../helpers/fetchUserOrganization";

type PostLogManagementProps = {
    uid?: string | undefined;
};

const PostLogManagement: React.FC<PostLogManagementProps> = ({ uid }) => {
    const { currentUser, userRole } = useAuth();
    const [selectedOrganization, setSelectedOrganization] = useState<string>("");

    useEffect(() => {
        if (!uid) {
            fetchUserOrganization(currentUser, setSelectedOrganization);
        } else {
            setSelectedOrganization(uid);
        }
    }, [currentUser, uid]);

    const renderPostLogManagementComponents = () => {
        if (userRole === "root") {
            return (
                <>
                    <OrganizationSelect
                        selectedOrganization={selectedOrganization}
                        setSelectedOrganization={setSelectedOrganization}
                    />
                    <PostLogManagementAdmin
                        selectedOrganization={selectedOrganization}
                    />
                </>
            );
        } else if (userRole === "admin") {
            return (
                <PostLogManagementAdmin
                    selectedOrganization={selectedOrganization}
                />
            );
        }
    };

    return <div>{renderPostLogManagementComponents()}</div>;
};

export default PostLogManagement;