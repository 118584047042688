import React from "react";
import Box from "@mui/material/Box";

interface HamburgerIconProps {
  open: boolean;
  onClick?: () => void;
}

const HamburgerIcon: React.FC<HamburgerIconProps> = ({ open, onClick }) => (
  <Box
    sx={{
      width: "30px",
      height: "24px",
      position: "relative",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <Box
      sx={{
        position: "absolute",
        top: open ? "50%" : "0",
        left: "50%",
        transform: open
          ? "translate(-50%, -50%) rotate(45deg)"
          : "translate(-50%, 0)",
        width: "100%",
        height: "3px",
        backgroundColor: "currentColor",
        transition: "all 0.3s",
      }}
    />
    <Box
      sx={{
        opacity: open ? 0 : 1,
        height: "3px",
        backgroundColor: "currentColor",
        transition: "opacity 0.3s",
      }}
    />
    <Box
      sx={{
        position: "absolute",
        bottom: open ? "50%" : "0",
        left: "50%",
        transform: open
          ? "translate(-50%, 50%) rotate(-45deg)"
          : "translate(-50%, 0)",
        width: "100%",
        height: "3px",
        backgroundColor: "currentColor",
        transition: "all 0.3s",
      }}
    />
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "3px",
        backgroundColor: "currentColor",
        opacity: open ? 0 : 1,
        transition: "opacity 0.3s",
      }}
    />
  </Box>
);

export default HamburgerIcon;
