import React, { useEffect, useState } from "react";
import UserManagementAdmin from "../components/Users/UserManagementAdmin";
import OrganizationSelect from "../components/OrganizationSelect/OrganizationSelect";
import { useAuth } from "../contexts/AuthContext";
import { fetchUserOrganization } from "../helpers/fetchUserOrganization";

type UserManagementProps = {
    uid?: string | undefined;
};

const UserManagement: React.FC<UserManagementProps> = ({ uid }) => {
    const { userRole, currentUser } = useAuth();
    const [selectedOrganization, setSelectedOrganization] = useState<string>("");

    useEffect(() => {
        if (!uid) {
            fetchUserOrganization(currentUser, setSelectedOrganization);
        } else {
            setSelectedOrganization(uid);
        }
    }, [currentUser]);

    const renderUserManagementComponents = () => {
        if (userRole === "root") {
            return (
                <>
                    <OrganizationSelect
                        selectedOrganization={selectedOrganization}
                        setSelectedOrganization={setSelectedOrganization}
                    />
                    <UserManagementAdmin selectedCompany={selectedOrganization} />
                </>
            );
        }
        if (userRole === "admin") {
            return <UserManagementAdmin selectedCompany={selectedOrganization} />;
        }
    };

    return (
        <div>
            {renderUserManagementComponents()}
        </div>
    );
};

export default UserManagement;
