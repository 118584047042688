import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Container,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decryptWithKMS, encryptWithKMS } from "../../apis/KmsApi";
import { useAuth } from "../../contexts/AuthContext";
import {
    collection,
    db,
    doc,
    getDoc,
    setDoc,
    updateDoc,
} from "../../services/firebaseService";
import {
    BlogConfig,
    BlogConfigFormProps,
} from "../../types/configurations/adminConfigurations";
import { ADMIN_CONFIG_PATH } from "../../paths";

const AdminConfigForm: React.FC<BlogConfigFormProps> = ({
    mode,
    uid,
    blog_id,
}) => {
    const [formData, setFormData] = useState<BlogConfig>({
        name: "",
        type_of_llm: "gpt-3.5-turbo",
        prompt: ["Example prompt", "next example prompt"],
        access_token: "",
        database_id: "",
        is_active: false
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const { currentUser } = useAuth();
    const [formErrors, setFormErrors] = useState<Partial<BlogConfig>>({});
    const [promptInputs, setPromptInputs] = useState<string[]>(
        formData.prompt || [""]
    );
    const [decodedAccessToken, setDecodedAccessToken] = useState(false);
    const [decodedDatabaseId, setDecodedDatabaseId] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlogConfigData = async () => {
            if (blog_id) {
                const blogDocRef = doc(
                    db,
                    `${ADMIN_CONFIG_PATH}/${uid}/blog`,
                    blog_id
                );
                const blogDocSnapshot = await getDoc(blogDocRef);

                if (blogDocSnapshot.exists()) {
                    const blogData = blogDocSnapshot.data() as BlogConfig;
                    setFormData(blogData); // Set initial form data from Firestore
                    setPromptInputs(blogData.prompt);

                    const idToken = await getAuth().currentUser?.getIdToken(true);
                    if (!idToken) {
                        return;
                    }
                    try {
                        if(blogData.access_token == ''){
                            setDecodedAccessToken(true);
                        }else{
                            const decryptedAccessToken = await decryptWithKMS(
                                blogData.access_token,
                                idToken
                            );
                            blogData.access_token = decryptedAccessToken.plaintext;
                            setFormData(blogData);
                            setDecodedAccessToken(true);
                        }
                    } catch (error) {
                        console.error("Access Token Decryption error:", error);
                    }

                    try {
                        if(blogData.database_id == ''){
                            setDecodedDatabaseId(true);
                        }else{
                            const decryptedDatabaseId = await decryptWithKMS(
                                blogData.database_id,
                                idToken
                            );
                            blogData.database_id = decryptedDatabaseId.plaintext;
                            setFormData(blogData);
                            setDecodedDatabaseId(true);
                        }
                    } catch (error) {
                        console.error("Database Id Decryption error:", error);
                    }
                }
            }
        };

        if (blog_id) {
            fetchBlogConfigData();
        }
    }, [blog_id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handlePromptChange = (index: number, value: string) => {
        const updatedprompt = [...promptInputs];
        updatedprompt[index] = value;
        setPromptInputs(updatedprompt);
    };

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        setFormData({ ...formData, is_active: e.target.value === "true" });
    };

    const addNewPromptInput = () => {
        setPromptInputs([...promptInputs, ""]);
    };

    const deletePromptInput = (index: number) => {
        const updatedprompt = promptInputs.filter((_, i) => i !== index);
        setPromptInputs(updatedprompt);
    };

    const saveConfigDocument = async (
        mode: string,
        uid: string,
        data: BlogConfig
    ) => {
        try {
            const blogConfigData: Partial<BlogConfig> = {
                name: data.name,
                type_of_llm: data.type_of_llm,
                is_active: data.is_active ?? false,
                prompt: promptInputs,
                access_token: data.access_token,
                database_id: data.database_id
            };

            const idToken = await getAuth().currentUser?.getIdToken(true);
            if (!idToken) {
                return;
            }

            // Encrypt the access token using Google Cloud KMS
            if(data.access_token != ''){
                const encryptedAccessToken = await encryptWithKMS(
                    data.access_token,
                    idToken
                );
                blogConfigData.access_token = encryptedAccessToken.ciphertext;
            }
            
            // Encrypt the database id using Google Cloud KMS
            if(data.database_id != ''){
                const encryptedDatabaseId = await encryptWithKMS(
                    data.database_id,
                    idToken
                );
                blogConfigData.database_id = encryptedDatabaseId.ciphertext;
            }

            // Add the new configuration document to Firestore
            const configCollection = collection(
                db,
                `${ADMIN_CONFIG_PATH}/${uid}/blog`
            );

            const newConfigRef = mode === 'CREATE' ? doc(configCollection) : doc(configCollection, blog_id);
            mode === 'CREATE' ? setDoc(newConfigRef, {...blogConfigData}) : updateDoc(newConfigRef, {...blogConfigData});            
        } catch (error) {
            // Handle any errors during configuration creation
            console.error(error);
        }
    };

    // TO-DO implement comprehensive validation layer
    const validateForm = (): boolean => {
        let errors: Partial<BlogConfig> = {};
        let formIsValid = true;

        setFormErrors(errors);
        return formIsValid;
    };

    const handleFirebaseSubmit = async (data: BlogConfig) => {
        if (!validateForm()) {
            return;
        }
        try {
            if (currentUser && uid) {
                if (mode === "REGISTER") {
                    await saveConfigDocument('CREATE', uid, data);
                    setSnackbarMessage(
                        "Successfully created new configuration!"
                    );
                } else {
                    await saveConfigDocument('UPDATE', uid, data);
                    setSnackbarMessage("Successfully updated configuration!");
                }
            }

            setSnackbarOpen(true);
            navigate(`/admin-config-management/${uid}`);
        } catch (error: any) {
            console.error("Error processing configuration data:", error);
            setSnackbarMessage("Error: " + error.message);
            setSnackbarOpen(true);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleFirebaseSubmit(formData);
    };

    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        marginTop: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography component="h1" variant="h5">
                        {mode === "EDIT"
                            ? "Edit configuration"
                            : "Create new configuration"}
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 3 }}
                    >
                        <Grid container spacing={2} xs={12}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    label="LLM種別"
                                    name="type_of_llm"
                                    value={formData.type_of_llm}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignItems="center"
                                >
                                    <TextField
                                        required
                                        fullWidth
                                        label="アクセストークン"
                                        name="access_token"
                                        value={formData.access_token}
                                        onChange={handleChange}
                                        style={{ flex: 1 }} // Flex property to fill remaining space
                                    />
                                    {!decodedAccessToken &&
                                    mode === "EDIT" ? (
                                        <Typography
                                            style={{ marginLeft: "10px" }}
                                        >
                                            Decoding...
                                        </Typography>
                                    ) : (
                                        decodedAccessToken &&
                                        mode === "EDIT" && (
                                            <Typography
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <CheckIcon /> Decoded
                                            </Typography>
                                        )
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignItems="center"
                                >
                                    <TextField
                                        required
                                        fullWidth
                                        label="データベースID"
                                        name="database_id"
                                        value={formData.database_id}
                                        onChange={handleChange}
                                        style={{ flex: 1 }} // Flex property to fill remaining space
                                    />
                                    {!decodedDatabaseId &&
                                    mode === "EDIT" ? (
                                        <Typography
                                            style={{ marginLeft: "10px" }}
                                        >
                                            Decoding...
                                        </Typography>
                                    ) : (
                                        decodedDatabaseId &&
                                        mode === "EDIT" && (
                                            <Typography
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <CheckIcon /> Decoded
                                            </Typography>
                                        )
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    有効可否
                                </Typography>
                                <Select
                                    value={
                                        formData.is_active ? "true" : "false"
                                    }
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value={"true"}>有効</MenuItem>
                                    <MenuItem value={"false"}>無効</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    プロンプト
                                </Typography>
                                {promptInputs.map((prompt, index) => (
                                    <Box
                                        key={index}
                                        mb={2}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <TextField
                                            required
                                            fullWidth
                                            label={`Prompt ${index + 1}`}
                                            multiline
                                            rows={4} // You can adjust the number of rows as needed
                                            value={prompt}
                                            onChange={(e) =>
                                                handlePromptChange(
                                                    index,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Box>
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                >
                                    {mode === "EDIT" ? "Update" : "Create"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {promptInputs.map((prompt, index) => (
                                <Box
                                    key={index}
                                    mb={12}
                                    display="flex"
                                    alignItems="flex-end"
                                    sx={{
                                        top: "18rem",
                                        position: "relative",
                                        width: "2rem",
                                    }}
                                >
                                    <IconButton
                                        onClick={() => deletePromptInput(index)}
                                        color="secondary"
                                        style={{ marginLeft: "10px" }}
                                    >
                                        Delete
                                    </IconButton>
                                </Box>
                            ))}
                        </Grid>
                        <Grid item xs={6}>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                sx={{
                                    left: "12rem",
                                    top: "26.7rem",
                                    position: "relative",
                                    width: "2rem",
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    onClick={addNewPromptInput}
                                >
                                    Add Prompt
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    action={
                        <IconButton
                            size="small"
                            color="inherit"
                            onClick={() => setSnackbarOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    }
                />
            </Box>
        </Container>
    );
};

export default AdminConfigForm;
