import { Theme, ThemeOptions, createTheme } from "@mui/material/styles";
import { jaJP } from "@mui/material/locale";
import { baseThemeProps } from "./baseTheme";

const lightThemeProps = {
    palette: {
        mode: "light",
        primary: {
            main: "#3f51b5", //blue
        },
        secondary: {
            main: "#f50057", //red
        },
        info: {
            main: "#000000", //black
        },
    },
    ...baseThemeProps
}

export const lightTheme: Theme = createTheme(lightThemeProps as ThemeOptions, jaJP);