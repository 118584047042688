import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { deleteObject } from "firebase/storage";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
    collection,
    db,
    doc,
    getDoc,
    setDoc,
    storage,
    updateDoc,
} from "../../services/firebaseService";
import { Organization } from "../../types/organizations/organizations";
import { ORGANIZATIONS_PATH } from "../../paths";

export interface OrgFormProps {
    mode: "EDIT" | "REGISTER";
    uid?: string;
    handleLogoChange: (logo: string) => void;
}

const OrgForm: React.FC<OrgFormProps> = ({ mode, uid, handleLogoChange }) => {
    const [formData, setFormData] = useState<Partial<Organization>>({
        name: "",
        postal_code: "",
        address: "",
        phone_number: "",
        email: "",
        max_users: 1,
        logo: ""
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const { userRole, currentUser, organization } = useAuth();
    const [formErrors, setFormErrors] = useState<Partial<Organization>>({});
    const fileInputRef = useRef<File | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrgData = async () => {
            if (uid) {
                const orgDocRef = doc(db, ORGANIZATIONS_PATH, uid);
                const orgDocSnapshot = await getDoc(orgDocRef);

                if (orgDocSnapshot.exists()) {
                    const orgData = orgDocSnapshot.data() as Organization;
                    setFormData(orgData);
                }
            }
        };

        fetchOrgData();
    }, [uid]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const createOrgDocument = async (
        uid: string,
        data: Partial<Organization>,
        creatorEmail: string
    ) => {
        const orgData: Partial<Organization> = {
            name: data.name,
            postal_code: data.postal_code,
            searchable_postal_code: data.postal_code?.replace(/-/g, ""),
            address: data.address,
            phone_number: data.phone_number,
            searchable_phone_number: data.phone_number?.replace(/-/g, ""),
            email: data.email,
            max_users: data.max_users,
            modified_by: creatorEmail,
            created_by: creatorEmail,
            no: "001",
        };

        try {
            // Get the current timestamp
            const Timestamp = new Date();

            // Add the new organization document to Firestore
            const orgCollection = collection(db, ORGANIZATIONS_PATH);
            const newOrgRef = doc(orgCollection);

            setDoc(newOrgRef, {
                ...orgData,
                created_at: Timestamp,
                modified_at: Timestamp,
            });

            const orgId = (await newOrgRef).id;

            // Update the UID field with the new organization ID
            const orgRef = doc(db, ORGANIZATIONS_PATH, orgId);
            await setDoc(orgRef, { uid: orgId }, { merge: true });
        } catch (error) {
            // Handle any errors during organization creation
            console.error(error);
        }
    };

    const updateOrgDocument = async (
        uid: string,
        data: Partial<Organization>,
        currentUserEmail: string
    ) => {
        const orgData: Partial<Organization> = {
            name: data.name,
            postal_code: data.postal_code,
            address: data.address,
            phone_number: data.phone_number,
            email: data.email,
            max_users: data.max_users,
            no: "001",
        };

        try {
            const Timestamp = new Date();
            const orgRef = doc(db, `${ORGANIZATIONS_PATH}/${uid}`);
            await updateDoc(orgRef, {
                ...orgData,
                modified_by: currentUserEmail,
                modified_at: Timestamp,
            });
        } catch (error) {
            console.error("Error:", error);
        }
    };

    //TO-DO implement comprehensive validation layer
    const validateForm = (): boolean => {
        let errors: Partial<Organization> = {};
        let formIsValid = true;

        if (!formData.email) {
            formIsValid = false;
            errors.email = "メールアドレスは必須です"; // Email is required
        }

        if (!formData.name) {
            formIsValid = false;
            errors.name = "名前は必須です"; // Name is required
        }

        setFormErrors(errors);
        return formIsValid;
    };

    const handleFirebaseSubmit = async (data: Partial<Organization>) => {
        if (!validateForm()) {
            return;
        }
        try {
            let orgId = uid;

            if (mode === "REGISTER") {
                if (currentUser && currentUser.email) {
                    await createOrgDocument(
                        orgId !== undefined ? orgId : "",
                        data,
                        currentUser.email
                    );
                }

                setSnackbarMessage("Succesfully created new organization!");
                setSnackbarOpen(true);
                navigate("/org-management");
            } else if (mode === "EDIT") {
                if (currentUser && currentUser.email) {
                    await updateOrgDocument(
                        orgId !== undefined ? orgId : "",
                        data,
                        currentUser.email
                    );
                }

                setSnackbarMessage("Succesfully updated the organization!");
                setSnackbarOpen(true);
                navigate("/org-management");
            }
        } catch (error: any) {
            console.error("Error processing organization data:", error);
            setSnackbarMessage("Error: " + error.message);
            setSnackbarOpen(true);
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            fileInputRef.current = selectedFile;
            handleLogoUpload();
        }
    };

    const handleDeleteLogo = async () => {
        try {
            const storageRef = ref(storage, `companies/${uid}/logo.png`);
            await deleteObject(storageRef);

            // Empty the file's URL
            const downloadURL = "";
            fileInputRef.current = null;

            // Update Firestore document with the logo URL
            const orgDocRef = doc(db, ORGANIZATIONS_PATH, uid!);

            // Set the logo URL field in the document
            await setDoc(orgDocRef, { logo: downloadURL }, { merge: true });

            // Set the companyLogo state to the new logo URL
            if (uid === organization?.uid) {
                // Update companyLogo state with the new logo URL if user company is the same as copany which logo is changed
                handleLogoChange(downloadURL);
            }

            formData.logo = ""
            setFormData(formData)
            setSnackbarMessage("Succesfully deleted logo!");
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Error deleting file:", error);
        }
    };

    const handleLogoUpload = async () => {
        try {
            if (fileInputRef.current) {
                const storageRef = ref(storage, `companies/${uid}/logo.png`);
                await uploadBytes(storageRef, fileInputRef.current);

                // Get the uploaded file's URL
                const downloadURL = await getDownloadURL(storageRef);

                // Update Firestore document with the logo URL
                const orgDocRef = doc(db, ORGANIZATIONS_PATH, uid!);

                // Set the logo URL field in the document
                await setDoc(orgDocRef, { logo: downloadURL }, { merge: true });

                // Set the companyLogo state to the new logo URL
                if (uid === organization?.uid) {
                    // Update companyLogo state with the new logo URL if user company is the same as copany which logo is changed
                    handleLogoChange(downloadURL);
                }

                formData.logo = downloadURL
                setFormData(formData)
                setSnackbarMessage("Succesfully uploaded logo!");
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleFirebaseSubmit(formData);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h5">
                    {mode === "EDIT"
                        ? userRole === "root" || userRole === "admin"
                            ? "Edit organization"
                            : "Organization"
                        : "Create new organization"}
                </Typography>

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 3 }}
                >
                    <Grid container spacing={2} xs={12}>
                        {
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="名前"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        error={Boolean(formErrors.name)}
                                        helperText={formErrors.name}
                                        disabled={
                                            userRole !== "root" &&
                                            userRole !== "admin"
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="郵便番号"
                                        name="postal_code"
                                        value={formData.postal_code}
                                        onChange={handleChange}
                                        error={Boolean(formErrors.postal_code)}
                                        helperText={formErrors.postal_code}
                                        disabled={
                                            userRole !== "root" &&
                                            userRole !== "admin"
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="住所"
                                        name="address"
                                        value={formData.address}
                                        error={Boolean(formErrors.address)}
                                        helperText={formErrors.address}
                                        disabled={
                                            userRole !== "root" &&
                                            userRole !== "admin"
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="電話番号"
                                        name="phone_number"
                                        value={formData.phone_number}
                                        onChange={handleChange}
                                        error={Boolean(formErrors.phone_number)}
                                        helperText={formErrors.phone_number}
                                        disabled={
                                            userRole !== "root" &&
                                            userRole !== "admin"
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="メールアドレス"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        error={Boolean(formErrors.email)}
                                        helperText={formErrors.email}
                                        disabled={
                                            userRole !== "root" &&
                                            userRole !== "admin"
                                        }
                                    />
                                </Grid>
                                {userRole === "root" && (
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="最大ユーザ数"
                                        name="max_users"
                                        value={formData.max_users}
                                        onChange={handleChange}
                                        error={Boolean(
                                            formErrors.max_users
                                        )}
                                        helperText={formErrors.max_users}
                                        disabled={userRole !== "root"}
                                    />
                                </Grid>
                                )}
                            </>
                        }
                        {(userRole === "root" || userRole === "admin") && (
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="outlined"
                                color="primary"
                            >
                                {mode === "EDIT" ? "Update" : "Create"}
                            </Button>
                        </Grid>
                        )}
                    </Grid>

                    {(formData.logo) && (uid) && (
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={2}
                            sx={{
                                bottom: "10rem",
                                left: "35rem",
                                position: "relative",
                                width: "20rem",
                            }}
                        >
                            
                            <Box display="flex" justifyContent="flex-end">
                                <img src={formData.logo} alt="Logo" width={200}/>
                            </Box>
                        </Grid>
                    </Grid>
                    )}

                    {(userRole === "root" || userRole === "admin") && (uid) && (
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={2}
                            sx={{
                                bottom: "7.4rem",
                                left: "30rem",
                                position: "relative",
                                width: "2rem",
                            }}
                        >
                            <Box display="flex" justifyContent="flex-end">
                                <IconButton
                                    color="secondary"
                                    onClick={handleDeleteLogo}
                                >
                                    Delete Logo
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sx={{
                                bottom: "7.6rem",
                                left: "36rem",
                                position: "relative",
                                width: "2rem",
                            }}
                        >
                            <Box display="flex" justifyContent="flex-end">
                                <input
                                    accept="image/png"
                                    id="firm-logo"
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                />
                                <label
                                    htmlFor="firm-logo"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    <IconButton
                                        color="primary"
                                        component="span"
                                    >
                                        Upload Logo
                                    </IconButton>
                                </label>
                            </Box>
                        </Grid>
                    </Grid>
                    )}
                </Box>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                action={
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={() => setSnackbarOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            />
        </Container>
    );
};

export default OrgForm;
