import React, { useEffect, useState } from "react";
import ConfigManagementAdmin from "../components/Configurations/ConfigManagementAdmin";
import { useAuth } from "../contexts/AuthContext";
import { fetchUserOrganization } from "../helpers/fetchUserOrganization";
import OrganizationSelect from "../components/OrganizationSelect/OrganizationSelect";

type ConfigManagementProps = {
    uid?: string | undefined;
};

const ConfigManagement: React.FC<ConfigManagementProps> = ({ uid }) => {
    const { currentUser, userRole } = useAuth();
    const [selectedOrganization, setSelectedOrganization] =
        useState<string>("");

    useEffect(() => {
        if (!uid) {
            fetchUserOrganization(currentUser, setSelectedOrganization);
        } else {
            setSelectedOrganization(uid);
        }
    }, [currentUser]);

    const renderConfigManagementComponents = () => {
        if (userRole === "root") {
            return (
                <>
                    <OrganizationSelect
                        selectedOrganization={selectedOrganization}
                        setSelectedOrganization={setSelectedOrganization}
                    />
                    <ConfigManagementAdmin
                        selectedOrganization={selectedOrganization}
                    />
                </>
            );
        } else if (userRole === "admin") {
            return (
                <>
                    <ConfigManagementAdmin
                        selectedOrganization={selectedOrganization}
                    />
                </>
            );
        }
    };

    return (
        <div>
            {renderConfigManagementComponents()}
        </div>
    );
};

export default ConfigManagement;
