import {
    Box,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../services/firebaseService";
import { BlogLog } from "../../types/posts/postlog";
import PaginatedTable from "../PaginatedTable/PaginatedTable";
import { POST_LOG_PATH } from "../../paths";

interface PostLogManagementAdminProps {
    selectedOrganization: string | undefined;
}

const PostLogManagementAdmin: React.FC<PostLogManagementAdminProps> = ({
    selectedOrganization
}) => {
    const [postLogs, setPostLog] = useState<BlogLog[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPostsLog = async () => {
            try {
                // Fetch posts based on selectedOrganization and selectedAuthor
                const postsLogRef = collection(
                    db,
                    `${POST_LOG_PATH}/${selectedOrganization}/articles`
                );
                const postsLogSnapshot = await getDocs(postsLogRef);
                const postsLog: BlogLog[] = [];
                postsLogSnapshot.docs.map(
                    (doc) => {
                        const data = doc.data()
                        const flattenedData = data.blogs.flat();
                        flattenedData.forEach((element: BlogLog) => {
                            element.id = doc.id;
                            const date = new Date(element.timestamp.toDate());
                            const options = { timeZone: 'Asia/Tokyo' };
                            element.timestamp_string = date.toLocaleString('ja-JP', options) + ' JST';
                            postsLog.push(element)
                        });
                    }
                );
                setPostLog(postsLog);
            } catch (error) {
                console.error("Error fetching posts:", error);
            } finally {
                setLoading(false);
            }
        };
        if(selectedOrganization){
            fetchPostsLog();
        }
    }, [selectedOrganization]);

    const renderHeadings = () => {
        return <TableRow>
                <TableCell>投稿ID</TableCell>
                <TableCell>サービス名</TableCell>
                <TableCell>結果</TableCell>
                <TableCell>日時</TableCell>
                <TableCell>メッセージ</TableCell>
                <TableCell>スタックトレース</TableCell>
            </TableRow>
    }

    const renderRows = (slicedData: BlogLog[]) => {
        return slicedData.map((postlog, index) => (
            <TableRow key={index}>
                <TableCell>{postlog.id}</TableCell>
                <TableCell>{ postlog.service }</TableCell>
                <TableCell>{ postlog.result }</TableCell>
                <TableCell>{ postlog.timestamp_string }</TableCell>
                <TableCell>{ postlog.message }</TableCell>
                <TableCell>{ postlog.stack_trace ? postlog.stack_trace : 'N/A' }</TableCell>
            </TableRow>
        ));
    };
    
    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box p={2}>
            <PaginatedTable 
                tableData={postLogs}
                searchFields={['id', 'message']}
                renderHeadings={renderHeadings}
                renderRows={renderRows}
            />
        </Box>
    );
};

export default PostLogManagementAdmin;