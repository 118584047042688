import {
    Box,
    FormControl,
    InputLabel,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { db } from "../../services/firebaseService";
import { Author, Post } from "../../types/posts/post";
import PaginatedTable from "../PaginatedTable/PaginatedTable";
import { POSTS_PATH } from "../../paths";

interface PostManagementAdminProps {
    selectedOrganization: string | undefined;
}

const PostManagementAdmin: React.FC<PostManagementAdminProps> = ({
    selectedOrganization
}) => {
    const [authors, setAuthors] = useState<Author[]>([]);
    const [posts, setPosts] = useState<Post[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedAuthor, setSelectedAuthor] = useState<string>("");

    useEffect(() => {
        const fetchAuthors = async () => {
            try {
                const authorRef = collection(
                    db,
                    `${POSTS_PATH}/${selectedOrganization}/authors/`
                );
                const authorSnapshot = await getDocs(authorRef);
                const authorData: Author[] = authorSnapshot.docs.map((doc) => ({
                    name: doc.id,
                }));
                const defaultAuthor = authorData.length ? authorData[0].name : 'none';
                setSelectedAuthor(defaultAuthor);                
                setAuthors(authorData);
            } catch (error) {
                console.error("Error fetching authors:", error);
            }
        };

        if(selectedOrganization){
            fetchAuthors();
        }
    }, [selectedOrganization]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                // Fetch posts based on selectedOrganization and selectedAuthor
                const postsRef = collection(
                    db,
                    `${POSTS_PATH}/${selectedOrganization}/authors/${selectedAuthor}/articles`
                );
                const orderedQuery = query(postsRef, orderBy('timestamp', 'desc'));
                const postsSnapshot = await getDocs(orderedQuery);
                const postsData: Post[] = postsSnapshot.docs.map(
                    (doc) => {
                        const data = doc.data()
                        data['id'] = doc.id
                        return data as Post
                    }
                );
                setPosts(postsData);
            } catch (error) {
                console.error("Error fetching posts:", error);
            } finally {
                setLoading(false);
            }
        };

        if(selectedAuthor){
            fetchPosts();
        }
    }, [selectedAuthor]);

    const handleAuthorChange = (
        event: SelectChangeEvent<string>,
        child: React.ReactNode
    ) => {
        setSelectedAuthor(event.target.value);
    };

    const renderHeadings = () => {
        return <TableRow>
                <TableCell>タイトル</TableCell>
                <TableCell>カテゴリ</TableCell>
                <TableCell>作成者</TableCell>
                <TableCell>日時</TableCell>
            </TableRow>
    }

    const renderRows = (slicedData: Post[]) => {
        return slicedData.map((post: Post, index: number) => (
            <TableRow key={index}>
                <TableCell>
                    <Link component={RouterLink} to={`/post-management/${selectedOrganization}/${selectedAuthor}/${post.id}`}>
                        {post.title}
                    </Link>
                </TableCell>
                <TableCell>{ post.category }</TableCell>
                <TableCell>{ post.author }</TableCell>
                <TableCell>{ post.timestamp }</TableCell>
            </TableRow>
        ));
    };

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box p={2}>
            <Typography variant="subtitle1" gutterBottom>
                著者を選んでください
            </Typography>
            <Box mt={1} marginBottom={5}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="author-select-label">
                        選択された著者
                    </InputLabel>
                    <Select
                        labelId="author-select-label"
                        id="author-select"
                        value={selectedAuthor}
                        onChange={handleAuthorChange}
                        label="author"
                    >
                        {authors.map((author) => (
                            <MenuItem key={author.name} value={author.name}>
                                {author.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <PaginatedTable 
                tableData={posts}
                searchFields={['title', 'category']}
                renderHeadings={renderHeadings}
                renderRows={renderRows}
            />
        </Box>
    );
};

export default PostManagementAdmin;