import React, { useState, useEffect } from "react";
import {
    TextField,
    Button,
    Typography,
    Container,
    Grid,
    Box,
    SelectChangeEvent,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { db, doc, collection, setDoc } from "../../services/firebaseService";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../contexts/AuthContext";
import {
    ConfigFormProps,
    Configuration,
} from "../../types/configurations/configurations";
import { SYSTEM_CONFIG_PATH } from "../../paths";

const ConfigForm: React.FC<ConfigFormProps> = ({ uid }) => {
    const [formData, setFormData] = useState<Partial<Configuration>>({
        fetch_type: "",
        fetch_range_hours: 0,
        fetch_range_offset: 0,
        number_of_threads: 0,
        reaction_type: "",
        start_reaction: "",
        end_reaction: "",
        uid: uid,
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const { currentUser } = useAuth();
    const [formErrors, setFormErrors] = useState<Partial<Configuration>>({});

    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (
            name === "number_of_threads" ||
            name === "fetch_range_hours" ||
            name === "fetch_range_offset"
        ) {
            setFormData((prev) => ({
                ...prev,
                [name]: parseInt(value, 10),
            }));
        } else {
            // Update the state with the new formData
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleFetchTypeChange = (
        event: SelectChangeEvent<string>,
        child: React.ReactNode
    ) => {    
        setFormData((prev) => ({ ...prev, ['fetch_type']: event.target.value }));
    };

    const createConfigDocument = async (
        uid: string,
        data: Partial<Configuration>
    ) => {
        const configData: Partial<Configuration> = {
            fetch_type: data.fetch_type,
            fetch_range_hours: data.fetch_range_hours,
            fetch_range_offset: data.fetch_range_offset,
            number_of_threads: data.number_of_threads,
            reaction_type: data.reaction_type,
            start_reaction: data.start_reaction,
            end_reaction: data.end_reaction,
            uid: uid,
        };

        try {
            // Get the current timestamp
            const Timestamp = new Date();

            // Add the new configuration document to Firestore
            const configCollection = collection(
                db,
                SYSTEM_CONFIG_PATH
            );
            const newConfigRef = doc(configCollection, uid);

            setDoc(newConfigRef, {
                ...configData,
                user_service_started_at: Timestamp,
            });
        } catch (error) {
            // Handle any errors during configuration creation
            console.error(error);
        }
    };

    //TO-DO implement comprehensive validation layer
    const validateForm = (): boolean => {
        let errors: Partial<Configuration> = {};
        let formIsValid = true;

        setFormErrors(errors);
        return formIsValid;
    };

    const handleFirebaseSubmit = async (data: Partial<Configuration>) => {
        if (!validateForm()) {
            return;
        }
        try {
            if (currentUser && uid) {
                await createConfigDocument(uid, data);
            }

            setSnackbarMessage("Succesfully created new configuration!");
            setSnackbarOpen(true);
            navigate(`/config-management/${uid}`);
        } catch (error: any) {
            console.error("Error processing configuration data:", error);
            setSnackbarMessage("Error: " + error.message);
            setSnackbarOpen(true);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleFirebaseSubmit(formData);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h5">
                    Create new configuration
                </Typography>

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 3 }}
                >
                    <Grid container spacing={2}>
                        {
                            <>
                                <Grid item xs={12}>
                                    <InputLabel id="fetch-type-input-label">
                                    取得種別
                                    </InputLabel>
                                    <Select
                                        label="fetch_type"
                                        labelId="fetch-type-select-label"
                                        id="fetch-type-select"
                                        value={formData.fetch_type}
                                        onChange={handleFetchTypeChange}
                                    >
                                        <MenuItem key="thread" value="thread">
                                        スレッド
                                        </MenuItem>
                                        <MenuItem key="start-end" value="start-end">
                                        開始-終了
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="スレッド数"
                                        name="number_of_threads"
                                        value={formData.number_of_threads}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="フェッチ範囲時間"
                                        name="fetch_range_hours"
                                        value={formData.fetch_range_hours}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="データ集計期間（n時間分）"
                                        name="fetch_range_offset"
                                        value={formData.fetch_range_offset}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="リアクション（Slack）"
                                        name="reaction_type"
                                        value={formData.reaction_type}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="開始のリアクション (Slack)"
                                        name="start_reaction"
                                        value={formData.start_reaction}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="終了のリアクション (Slack)"
                                        name="end_reaction"
                                        value={formData.end_reaction}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="outlined"
                                color="primary"
                            >
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                action={
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={() => setSnackbarOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            />
        </Container>
    );
};

export default ConfigForm;
