
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../services/firebaseService";
import { Post } from '../../types/posts/post';
import { Box, Container, Paper, Typography } from '@mui/material';
import { POSTS_PATH } from '../../paths';

const PostDetails: React.FC = () => {
  const { currentUser, userRole } = useAuth();
  const { orgId, authorId, postId } = useParams();
  const [post, setPost] = useState<Post>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
        try {
            // Fetch posts based on selectedOrganization and selectedAuthor
            const postsRef = collection(
                db,
                `${POSTS_PATH}/${orgId}/authors/${authorId}/articles`
            );
            const postsSnapshot = await getDocs(postsRef);
            const postsData: Post[] = postsSnapshot.docs.map(
                (doc) => {
                    const data = doc.data()
                    data['id'] = doc.id
                    return data as Post
                }
            );
            // Filter for only one post with postId
            const postData = postsData.filter((post) => post.id === postId)[0]
            setPost(postData);
        } catch (error) {
            console.error("Error fetching posts:", error);
        } finally {
          setLoading(false);
        }
    };
    fetchPosts();
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if ((userRole === "root" || userRole === "admin") && post) {
    return (
      <Box p={2}>
        <Container maxWidth="md">
          <Paper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 5 }}>
            <Typography variant="h4" gutterBottom>
              {post.title}
            </Typography>
            <Typography variant="body1" paragraph>
              <b>カテゴリ:</b> {post.category}
            </Typography>
            <Typography variant="body1" paragraph>
              <b>作成者:</b> {post.author}
            </Typography>
          </Paper>
      </Container>

      <Container maxWidth="md">
          <Paper style={{ marginTop: 50, padding: 20 }}>
            <Typography variant="body1" paragraph>
              <b>日時:</b> {post.timestamp}
            </Typography>
            <Typography variant="body1" paragraph>
              <b>スレッド:</b> {post.threads}
            </Typography>
            <Typography variant="body1" paragraph>
              <b>投稿ステータス</b><hr/>
              {
                post.blog_contents['qiita'] &&
                <>
                  <p><b>Qiita:</b> {post.post_status['qiita'] ? 'Published' : 'Unpublished'}</p>
                </>
              }
              {
                post.blog_contents['zenn'] &&
                <>
                  <p><b>Zenn:</b> {post.post_status['zenn'] ? 'Published' : 'Unpublished'}</p>
                </>
              }
              {
                post.blog_contents['notion'] &&
                <>
                  <p><b>Notion:</b> {post.post_status['notion'] ? 'Published' : 'Unpublished'}</p>
                </>
              }
            </Typography>
            <Typography variant="body1" paragraph>
              <b>本文</b><hr/>
              {post.sanitized_content}
            </Typography>
            <Typography variant="body1" paragraph>
              <b>ブログコンテンツ</b><hr/>
              {
                (post.blog_contents['qiita']) &&
                (<>
                  <b>Qiita:</b> <hr/> {post.blog_contents['qiita']}
                </>
                )
              }
              {
                (post.blog_contents['zenn']) &&
                (<>
                  <p></p> <b>Zenn:</b> <hr/> {post.blog_contents['zenn']}
                </>
                )
              }
              {
                (post.blog_contents['notion']) &&
                (<>
                  <p></p> <b>Notion:</b> <hr/> {post.blog_contents['notion']}
                </>
                )
              }
            </Typography>
          </Paper>
      </Container>
      </Box>
    );
  } else {
    return <Typography>404 Not Found...</Typography>;
  }
};

export default PostDetails;