import {
    Box,
    Button,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../services/firebaseService";
import { Secrets } from "../../types/secrets/secrets";
import { SECRETS_PATH } from "../../paths";

interface SecretsManagementAdminProps {
    selectedOrganization: string | undefined;
}

const SecretManagementAdmin: React.FC<SecretsManagementAdminProps> = ({
    selectedOrganization,
}) => {
    const { currentUser, userRole } = useAuth();
    const [secretData, setSecretData] = useState<Secrets | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSecrets = async () => {
            setLoading(true);
            if (!currentUser || !selectedOrganization) return;

            const selectedOrganizationSecretRef = doc(
                db,
                SECRETS_PATH,
                selectedOrganization
            );

            try {
                const secretDocSnap = await getDoc(
                    selectedOrganizationSecretRef
                );
                const secretData = secretDocSnap.data() as Secrets;

                setSecretData(secretData);
            } catch (error) {
                console.error("Error fetching secrets:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSecrets();
    }, [selectedOrganization]);

    const handleCreateSecret = async () => {
        if (!currentUser || !selectedOrganization) return;

        const selectedOrganizationSecretRef = doc(
            db,
            SECRETS_PATH,
            selectedOrganization
        );

        try {
            await setDoc(selectedOrganizationSecretRef, {
                slack_channels: [],
                teams_channels: [],
            });
            setSecretData({
                slack_channels: [],
                teams_channels: [],
            });
        } catch (error) {
            console.error("Error writing document: ", error);
        }
    };

    const handleCreateChannel = async (channel_type: string) => {
        navigate(`/new/${channel_type}/${selectedOrganization}`, {
            state: { organizationUid: selectedOrganization },
        });
    };

    const handleEditChannel = (channel_id: string, channel_type: string) => {
        navigate(
            `/edit/${channel_type}/${selectedOrganization}/${channel_id}`,
            {
                state: { organizationUid: selectedOrganization },
            }
        );
    };

    const handleDeleteSecret = async () => {
        if (!window.confirm("この秘匿情報を削除してもよろしいですか？")) {
            return;
        }

        if (!selectedOrganization) return;

        try {
            const secretDocRef = doc(db, SECRETS_PATH, selectedOrganization);
            await deleteDoc(secretDocRef);

            setSecretData(null);

            setSnackbarMessage("秘匿情報を正常に削除しました");
            setSnackbarOpen(true);
        } catch (error) {
            console.error("秘匿情報の削除中にエラーが発生しました：", error);
        }
    };

    const handleDeleteChannel = async (
        channel_id: string,
        channel_type: string
    ) => {
        if (
            !window.confirm("このチャネル 秘匿情報を削除してもよろしいですか？")
        ) {
            return;
        }

        if (!selectedOrganization || !secretData) return;

        const secretDocRef = doc(db, SECRETS_PATH, selectedOrganization);

        try {
            if (channel_type === "Slack") {
                const updatedSlackChannels = secretData.slack_channels.filter(
                    (channel) => channel.channel_id !== channel_id
                );

                await updateDoc(secretDocRef, {
                    slack_channels: updatedSlackChannels,
                });

                setSecretData({
                    slack_channels: updatedSlackChannels,
                    teams_channels: secretData.teams_channels,
                });
            } else if (channel_type === "Teams") {
                const updatedTeamsChannels = secretData.teams_channels.filter(
                    (channel) => channel.channel_id !== channel_id
                );

                await updateDoc(secretDocRef, {
                    teams_channels: updatedTeamsChannels,
                });

                setSecretData({
                    slack_channels: secretData.slack_channels,
                    teams_channels: updatedTeamsChannels,
                });
            }

            setSnackbarMessage("チャネル 秘匿情報を正常に削除しました");
            setSnackbarOpen(true);
        } catch (error) {
            console.error(
                "チャネル 秘匿情報の削除中にエラーが発生しました：",
                error
            );
        }
    };

    const cellStyle = {
        width: "20rem",
        maxWidth: "20rem",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    };

    const renderChannelRows = (channels: Array<any>, channelType: string) => {
        return channels.map((channel, index) => (
            <TableRow key={index}>
                <TableCell align="left" sx={cellStyle}>
                    {channel.channel_id}
                </TableCell>
                <TableCell sx={cellStyle} align="left">
                    {channel.access_token}
                </TableCell>
                <TableCell align="right">
                    {channel.is_active ? "有効" : "無効"}
                </TableCell>
                <TableCell align="right">
                    <Box pr={1} component="span">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                                handleEditChannel(
                                    channel.channel_id,
                                    channelType
                                )
                            }
                            disabled={userRole !== "root" && userRole !== "admin"}
                        >
                            編集 {/* Edit */}
                        </Button>
                    </Box>
                    {userRole === "root" && 
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() =>
                            handleDeleteChannel(channel.channel_id, channelType)
                        }
                        disabled={userRole !== "root"}
                    >
                        削除 {/* Delete */}
                    </Button>
                    }
                </TableCell>
            </TableRow>
        ));
    };

    return (
        <Box p={2} sx={{ paddingTop: "0px" }}>
            {selectedOrganization && !secretData && (
                <>
                    <Typography variant="subtitle1" gutterBottom>
                        秘匿情報は作成されません
                    </Typography>
                    {userRole === "root" &&
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCreateSecret}
                        disabled={userRole !== "root" || loading}
                    >
                        秘匿情報作成{/* Create New Secret */}
                    </Button>
                    }
                </>
            )}
            {/* Display slack_channels */}
            {secretData && secretData.slack_channels && (
                <>
                    {userRole === "root" &&
                    <Box my={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleCreateChannel("Slack")}
                            disabled={userRole !== "root" || loading}
                        >
                            新しい Slack チャネル 秘匿情報の作成
                            {/* Create New Slack Channel Secret */}
                        </Button>
                    </Box>
                    }
                    {/* New Slack Channel Button */}
                    <Typography variant="subtitle1" gutterBottom>
                        Slack チャネル
                    </Typography>
                    {/* Slack Channels Table */}
                    <TableContainer component={Paper}>
                        <Table aria-label="Slack Channels">
                            <TableHead>
                                <TableRow>
                                    <TableCell>チャンネルID</TableCell>
                                    <TableCell>アクセストークン</TableCell>
                                    <TableCell align="right">
                                        有効可否
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderChannelRows(
                                    secretData.slack_channels,
                                    "Slack"
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            {secretData && secretData.teams_channels && false && (
                <>
                    <Box my={2} sx={{ paddingTop: "15px" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleCreateChannel("Teams")}
                            disabled={userRole !== "root" || loading}
                        >
                            新しい Teams チャネル 秘匿情報の作成
                            {/* Create New Teams Channel Secret */}
                        </Button>
                    </Box>
                    {/* New Teams Channel Button */}
                    <Typography variant="subtitle1" gutterBottom>
                        Teams チャネル
                    </Typography>
                    {/* Teams Channels Table */}
                    <TableContainer component={Paper}>
                        <Table aria-label="Teams Channels">
                            <TableHead>
                                <TableRow>
                                    <TableCell>チャンネルID</TableCell>
                                    <TableCell>アクセストークン</TableCell>
                                    <TableCell align="right">
                                        有効可否
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderChannelRows(
                                    [],
                                    "Teams"
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            {userRole === "root" &&
            <Box my={2} sx={{ paddingTop: "15px" }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleDeleteSecret}
                    disabled={userRole !== "root"}
                >
                    秘匿情報削除 {/* Delete */}
                </Button>
            </Box>
            }
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            />
        </Box>
    );
};

export default SecretManagementAdmin;
