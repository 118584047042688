import { User } from "../types/users/users";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function createUser(user: Partial<User>, idToken: string) {
    try {
        const response = await fetch(`${BASE_URL}/auth/users`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify(user),
        });
        return await response.json();
    } catch (error) {
        console.error("Error:", error);
    }
}

export async function getUser(userId: string, idToken: string) {
    try {
        const response = await fetch(`${BASE_URL}/auth/users/${userId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.error("Error:", error);
    }
}

export async function updateUser(
    userId: string,
    user: Partial<User>,
    idToken: string
) {
    try {
        const response = await fetch(`${BASE_URL}/auth/users/${userId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify(user),
        });
        return await response.json();
    } catch (error) {
        console.error("Error:", error);
    }
}

export async function deleteUser(userId: string, idToken: string) {
    try {
        const response = await fetch(`${BASE_URL}/auth/users/${userId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.error("Error:", error);
    }
}
