import React, { useState } from "react";
import {
    Container,
    Typography,
    Box,
    Radio,
    FormControlLabel,
    RadioGroup,
} from "@mui/material";

type ThemeTypes = "light" | "dark";

interface SettingsProps {
    currentTheme: ThemeTypes;
    handleThemeChange: (theme: ThemeTypes) => void;
}

const Settings: React.FC<SettingsProps> = ({ handleThemeChange, currentTheme }) => {
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = event.target.value as ThemeTypes;
        handleThemeChange(selectedValue);
    };

    return (
        <Container maxWidth="md">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    設定 (Settings)
                </Typography>

                <RadioGroup
                    aria-label="colorScheme"
                    name="colorScheme"
                    value={currentTheme}
                    onChange={handleRadioChange}
                >
                    <FormControlLabel
                        value="light"
                        control={<Radio />}
                        label="Light Mode"
                    />
                    <FormControlLabel
                        value="dark"
                        control={<Radio />}
                        label="Dark Mode"
                    />
                </RadioGroup>
            </Box>
        </Container>
    );
};

export default Settings;
