import React, { useEffect, useState } from "react";
import AdminConfigManagementRoot from "../components/Admin-Configurations/AdminConfigManagementRoot";
import { useAuth } from "../contexts/AuthContext";
import { fetchUserOrganization } from "../helpers/fetchUserOrganization";

type AdminConfigManagementProps = {
    uid?: string | undefined;
};

const AdminConfigManagement: React.FC<AdminConfigManagementProps> = ({
    uid,
}) => {
    const { currentUser, userRole } = useAuth();
    const [selectedOrganization, setSelectedOrganization] =
        useState<string>("");

    useEffect(() => {
        if (!uid) {
            fetchUserOrganization(currentUser, setSelectedOrganization);
        } else {
            setSelectedOrganization(uid);
        }
    }, [currentUser, uid]);

    const renderConfigManagementComponents = () => {
        if (userRole === "root") {
            return (
                <>
                    <AdminConfigManagementRoot
                        selectedOrganization={selectedOrganization}
                        setSelectedOrganization={setSelectedOrganization}
                    />
                </>
            );
        }
    };

    return (
        <div>
            {renderConfigManagementComponents()}
        </div>
    );
};

export default AdminConfigManagement;
