import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Container,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { getAuth } from "firebase/auth";
import { arrayUnion, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decryptWithKMS, encryptWithKMS } from "../../apis/KmsApi";
import { useAuth } from "../../contexts/AuthContext";
import { collection, db, doc, updateDoc } from "../../services/firebaseService";
import { Channel, SecretFormProps, Secrets } from "../../types/secrets/secrets";
import { SECRETS_PATH } from "../../paths";

const SecretForm: React.FC<SecretFormProps> = ({
    mode,
    uid,
    channel_type,
    channel_id,
}) => {
    const [formData, setFormData] = useState<Channel>({
        channel_name: "",
        channel_id: "",
        access_token: "",
        refresh_token: "",
        is_active: true,
        notify_dest_access_token: "",
        notify_dest_channel_id: "",
        notify_dest_channel_name: "",
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const { currentUser } = useAuth();
    const [formErrors, setFormErrors] = useState<Partial<Secrets>>({});
    const [decodedAccessToken, setDecodedAccessToken] = useState(false);
    const [decodedRefreshToken, setDecodedRefreshToken] = useState(false);
    const [decodedNotifyAccessToken, setDecodedNotifyAccessToken] = useState(false);

    const isTeamsChannel = (channel_type === "Teams")
    const isSlackChannel = (channel_type === "Slack")

    const navigate = useNavigate();

    useEffect(() => {
        const fetchSecretData = async () => {
            if (!uid || mode !== "EDIT") {
                return;
            }

            const secretDocRef = doc(db, SECRETS_PATH, uid);
            const secretDocSnapshot = await getDoc(secretDocRef);

            if (!secretDocSnapshot.exists()) {
                return;
            }

            const secretData = secretDocSnapshot.data() as Secrets;

            const specificChannel =
                isSlackChannel
                    ? secretData.slack_channels.find(
                          (channel) => channel.channel_id === channel_id
                      )
                    : secretData.teams_channels.find(
                          (channel) => channel.channel_id === channel_id
                      );

            if (!specificChannel) {
                return;
            }

            setFormData(specificChannel);

            const idToken = await getAuth().currentUser?.getIdToken(true);

            if (!idToken) {
                return;
            }

            try {
                const decryptedAccessToken = await decryptWithKMS(
                    specificChannel.access_token,
                    idToken
                );
                specificChannel.access_token = decryptedAccessToken.plaintext;
                setFormData(specificChannel);
                setDecodedAccessToken(true);
            } catch (error) {
                console.error("Access Token Decryption error:", error);
            }

            try {
                const decryptedNotifyToken = await decryptWithKMS(
                    specificChannel.notify_dest_access_token,
                    idToken
                );
                specificChannel.notify_dest_access_token = decryptedNotifyToken.plaintext;
                setFormData(specificChannel);
                setDecodedNotifyAccessToken(true);
            } catch (error) {
                console.error("Notify Access Token Decryption error:", error);
            }

            if(isTeamsChannel){
                try {
                    const decryptedRefreshToken = await decryptWithKMS(
                        specificChannel.refresh_token,
                        idToken
                    );
                    specificChannel.refresh_token = decryptedRefreshToken.plaintext;
                    setFormData(specificChannel);
                    setDecodedRefreshToken(true);
                } catch (error) {
                    console.error("Refresh Token Decryption error:", error);
                }
            }
        };

        fetchSecretData();
    }, [uid, mode, channel_type, channel_id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        setFormData({ ...formData, is_active: e.target.value === "true" });
    };

    const createChannelSecret = async (uid: string, data: Channel) => {
        if (!uid || !currentUser) {
            return;
        }

        const channelData: Partial<Channel> = {
            channel_name: data.channel_name,
            channel_id: data.channel_id,
            is_active: data.is_active,
            notify_dest_channel_name: data.notify_dest_channel_name,
            notify_dest_channel_id: data.notify_dest_channel_id
        };

        const idToken = await getAuth().currentUser?.getIdToken(true);

        if (!idToken) {
            return;
        }

        try {
            // Encrypt the access token using Google Cloud KMS
            const encryptedAccessToken = await encryptWithKMS(
                data.access_token,
                idToken
            );
            // Encrypt the notify access token using Google Cloud KMS
            const encryptedNotifyAccessToken = await encryptWithKMS(
                data.notify_dest_access_token,
                idToken
            );
            // Encrypt the refresh token using Google Cloud KMS
            let encryptedRefreshToken = {ciphertext: ''};
            if(isTeamsChannel){
                encryptedRefreshToken = await encryptWithKMS(
                    data.refresh_token,
                    idToken
                );
            }            

            if (!encryptedAccessToken || !encryptedRefreshToken || !encryptedNotifyAccessToken) {
                return;
            }

            // Add the encrypted tokens to the channel data
            channelData.access_token = encryptedAccessToken.ciphertext;
            channelData.notify_dest_access_token = encryptedNotifyAccessToken.ciphertext;
            channelData.refresh_token = encryptedRefreshToken.ciphertext;          

            const secretCollection = collection(db, SECRETS_PATH);
            const secretRef = doc(secretCollection, uid);

            if (mode === "REGISTER") {
                // Use arrayUnion to add the new channelData object to the existing array
                const channel_data = isSlackChannel ? {slack_channels: arrayUnion(channelData)} : {teams_channels: arrayUnion(channelData)}
                await updateDoc(secretRef, channel_data);
            } else if (mode === "EDIT") {
                // Get the existing document data
                const secretDoc = await getDoc(secretRef);
                const existingData = secretDoc.data();
                if (existingData) {
                    const existingChannels = isSlackChannel ? existingData.slack_channels : existingData.teams_channels;
                    const updatedExistingChannels =
                        existingChannels.map(
                            (channel: {
                                channel_id: string | undefined;
                            }) => {
                                if (channel.channel_id === channel_id) {
                                    // Update the properties of the specific channel with the matching channel_id
                                    return {
                                        channel_id: channelData.channel_id,
                                        channel_name:
                                            channelData.channel_name,
                                        access_token:
                                            channelData.access_token,
                                        refresh_token:
                                            channelData.refresh_token,
                                        notify_dest_access_token:
                                            channelData.notify_dest_access_token,
                                        notify_dest_channel_id:
                                            channelData.notify_dest_channel_id,
                                        notify_dest_channel_name:
                                            channelData.notify_dest_channel_name,
                                        is_active: data.is_active, // or any other updated value
                                    };
                                }
                                return channel; // Return the unmodified channel
                            }
                        );

                    // Update the document with the modified array
                    const updatedChannels = isSlackChannel ? {slack_channels: updatedExistingChannels} : {teams_channels: updatedExistingChannels}
                    await updateDoc(secretRef, updatedChannels);
                }
            }
        } catch (error) {
            // Handle any errors during channel secret creation
            console.error(error);
        }
    };

    //TO-DO implement comprehensive validation layer
    const validateForm = (): boolean => {
        let errors: Partial<Secrets> = {};
        let formIsValid = true;

        setFormErrors(errors);
        return formIsValid;
    };

    const handleFirebaseSubmit = async (data: Channel) => {
        if (!validateForm()) {
            return;
        }
        try {
            if (currentUser && uid) {
                await createChannelSecret(uid, data);
            }

            setSnackbarMessage("Succesfully created new secret!");
            setSnackbarOpen(true);
            navigate(`/secret-management/${uid}`);
        } catch (error: any) {
            console.error("Error processing secret data:", error);
            setSnackbarMessage("Error: " + error.message);
            setSnackbarOpen(true);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleFirebaseSubmit(formData);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h5">
                    {mode === "EDIT"
                        ? "Edit channel secret"
                        : "Create new channel secret"}
                </Typography>

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 3 }}
                >
                    <Grid container spacing={2}>
                        {
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="チャンネル Name"
                                        name="channel_name"
                                        value={formData.channel_name}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="チャンネルID"
                                        name="channel_id"
                                        value={formData.channel_id}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        alignItems="center"
                                    >
                                        <TextField
                                            required
                                            fullWidth
                                            label="アクセストークン"
                                            name="access_token"
                                            value={formData.access_token}
                                            onChange={handleChange}
                                            style={{ flex: 1 }} // Flex property to fill remaining space
                                        />
                                        {!decodedAccessToken &&
                                        mode === "EDIT" ? (
                                            <Typography
                                                style={{ marginLeft: "10px" }}
                                            >
                                                Decoding...
                                            </Typography>
                                        ) : (
                                            formData.access_token &&
                                            mode === "EDIT" && (
                                                <Typography
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <CheckIcon /> Decoded
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                </Grid>
                                {isTeamsChannel && (
                                        <Grid item xs={12}>
                                            <Grid item xs={12} container alignItems="center">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    label="リフレッシュトークン"
                                                    name="refresh_token"
                                                    value={formData.refresh_token}
                                                    onChange={handleChange}
                                                    style={{ flex: 1 }}
                                                />
                                                {!decodedRefreshToken && mode === "EDIT" ? (
                                                    <Typography style={{ marginLeft: "10px" }}>
                                                        Decoding...
                                                    </Typography>
                                                ) : (
                                                    formData.access_token && mode === "EDIT" && (
                                                        <Typography
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <CheckIcon /> Decoded
                                                        </Typography>
                                                    )
                                                )}
                                            </Grid>
                                        </Grid>
                                )}
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="通知先 チャンネル Name"
                                        name="notify_dest_channel_name"
                                        value={formData.notify_dest_channel_name}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="通知先 チャンネルID"
                                        name="notify_dest_channel_id"
                                        value={formData.notify_dest_channel_id}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        alignItems="center"
                                    >
                                        <TextField
                                            required
                                            fullWidth
                                            label="通知先アクセストークン"
                                            name="notify_dest_access_token"
                                            value={formData.notify_dest_access_token}
                                            onChange={handleChange}
                                            style={{ flex: 1 }} // Flex property to fill remaining space
                                        />
                                        {!decodedNotifyAccessToken &&
                                        mode === "EDIT" ? (
                                            <Typography
                                                style={{ marginLeft: "10px" }}
                                            >
                                                Decoding...
                                            </Typography>
                                        ) : (
                                            formData.notify_dest_access_token &&
                                            mode === "EDIT" && (
                                                <Typography
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <CheckIcon /> Decoded
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">
                                        有効可否:
                                    </Typography>
                                    <Select
                                        value={
                                            formData.is_active
                                                ? "true"
                                                : "false"
                                        }
                                        onChange={handleSelectChange}
                                    >
                                        <MenuItem value={"true"}>有効</MenuItem>
                                        <MenuItem value={"false"}>無効</MenuItem>
                                    </Select>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="outlined"
                                color="primary"
                            >
                                {mode === "EDIT" ? "Update" : "Create"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                action={
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={() => setSnackbarOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            />
        </Container>
    );
};

export default SecretForm;
