import {
    Box,
    Button,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../services/firebaseService";
import { Organization } from "../../types/organizations/organizations";
import { ORGANIZATIONS_PATH } from "../../paths";

const OrgManagementRoot: React.FC = () => {
    const { currentUser, userRole } = useAuth();
    const [orgsData, setOrgsData] = useState<Organization[]>([]);
    const [fetchedOrgsData, setFetchedOrgsData] = useState<Organization[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const navigate = useNavigate();

    // Fetch organizations from Firestore
    useEffect(() => {
        const fetchOrganizations = async () => {
            setLoading(true);
            if (currentUser) {
                const orgsRef = collection(db, ORGANIZATIONS_PATH);
                try {
                    const querySnapshot = await getDocs(orgsRef);
                    const orgsData: Organization[] = [];
                    querySnapshot.forEach((doc) => {
                        const organizationData = doc.data() as Organization;
                        organizationData.uid = doc.id;
                        orgsData.push(organizationData);
                    });
                    setOrgsData(orgsData); // Update the fetched data
                    setLoading(false);
                } catch (error) {
                    console.error("Error fetching organizations:", error);
                    setLoading(false);
                }
            }
        };
        fetchOrganizations();
    }, [currentUser, fetchedOrgsData]);

    const handleEdit = (org: Organization) => {
        navigate(`/edit/org/${org.uid}`, {
            state: { organizationUid: org.uid },
        });
    };

    const handleCreateNewOrg = () => {
        navigate("/new/org");
    };

    const handleDelete = async (org: Organization) => {
        if (!window.confirm("この組織を削除してもよろしいですか？")) {
            return;
        }

        try {
            const orgDocRef = doc(db, ORGANIZATIONS_PATH, org.uid);
            await deleteDoc(orgDocRef);

            // Remove the deleted organization from orgsData
            setFetchedOrgsData((prevOrgsData) =>
                prevOrgsData.filter((item) => item.uid !== org.uid)
            );

            setSnackbarMessage(`組織を正常に削除しました ${org.name}`);
            setSnackbarOpen(true);
        } catch (error) {
            console.error("組織の削除中にエラーが発生しました：", error);
        }
    };

    return (
        <Box p={2}>
            <Box mb={2}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCreateNewOrg}
                    disabled={userRole !== "root" || loading}
                >
                    組織登録 {/* Register New Organization */}
                </Button>
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>名前</TableCell> {/* Name */}
                            <TableCell>住所</TableCell> {/* Address */}
                            <TableCell>作成日</TableCell> {/* Created At */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orgsData.map((org) => (
                            <TableRow key={org.uid}>
                                <TableCell>{org.name}</TableCell>
                                <TableCell>{org.address}</TableCell>
                                <TableCell>
                                    {org.created_at
                                        ?.toDate()
                                        .toLocaleDateString("ja-JP")}
                                </TableCell>
                                <TableCell align="right">
                                    <Box pr={1} component="span">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleEdit(org)}
                                            disabled={userRole !== "root"}
                                        >
                                            編集 {/* Edit */}
                                        </Button>
                                    </Box>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => handleDelete(org)}
                                        disabled={userRole !== "root"}
                                    >
                                        削除 {/* Delete */}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            />
        </Box>
    );
};

export default OrgManagementRoot;
