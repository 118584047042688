import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar/Navbar";

type ThemeTypes = "light" | "dark";
interface MainLayoutProps {
    selectedTheme: ThemeTypes;
    companyLogo: string;
}

const MainLayout: React.FC<MainLayoutProps> = ({ selectedTheme, companyLogo }) => {
    return (
        <div>
            <Navbar selectedTheme={selectedTheme} selectedCompanyLogo={companyLogo}/>
            <div>
                <Outlet />
            </div>
        </div>
    );
};

export default MainLayout;
