import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../services/firebaseService";
import { ORGANIZATIONS_PATH } from "../../paths";

interface Organization {
    uid: string;
    name: string;
}

interface OrganizationSelectProps {
    selectedOrganization: string | undefined;
    setSelectedOrganization: React.Dispatch<React.SetStateAction<string>>;
}

const OrganizationSelect: React.FC<OrganizationSelectProps> = ({
    selectedOrganization,
    setSelectedOrganization,
}) => {
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [orgLoading, setOrgLoading] = useState(true);

    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const orgsRef = collection(db, ORGANIZATIONS_PATH);
                const orgsSnapshot = await getDocs(orgsRef);
                const orgsData: Organization[] = orgsSnapshot.docs.map(
                    (doc) => ({
                        uid: doc.id,
                        name: doc.data().name,
                    })
                );
                setOrganizations(orgsData);
            } catch (error) {
                console.error("Error fetching organizations:", error);
            } finally {
                setOrgLoading(false);
            }
        };

        fetchOrganizations();
    }, []);

    const handleCompanyChange = (
        event: SelectChangeEvent<string>,
        child: React.ReactNode
    ) => {
        setSelectedOrganization(event.target.value);
    };

    if (orgLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (organizations.length === 0) {
        return <Typography>No organizations available.</Typography>;
    }

    return (
        <Box p={2}>
            <Typography variant="subtitle1" gutterBottom>
                組織を選んでください
            </Typography>
            <Box mt={1}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="company-select-label">
                        選択された組織
                    </InputLabel>
                    <Select
                        labelId="company-select-label"
                        id="company-select"
                        value={selectedOrganization}
                        onChange={handleCompanyChange}
                        label="company"
                    >
                        {organizations.map((organization) => (
                            <MenuItem
                                key={organization.uid}
                                value={organization.uid}
                            >
                                {organization.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};

export default OrganizationSelect;