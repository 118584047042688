import {
    Alert,
    Button,
    Container,
    Grid,
    Snackbar,
    TextField,
} from "@mui/material";
import { ConfirmationResult, RecaptchaVerifier } from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/bebuddy_white_logo.png";
import { useAuth } from "../contexts/AuthContext";
import { auth } from "../services/firebaseService";
import buttonStyles from "../styles/buttons";
import { maskPhoneNumber } from "../utils/maskPhoneNumber";

const Login: React.FC = () => {
    const { loginWithGoogleButton } = buttonStyles;
    const { loginWithButton } = buttonStyles;
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const { login, googleLogin, sendResetEmail, phoneLogin } = useAuth();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [confirmationResult, setConfirmationResult] =
        useState<ConfirmationResult | null>(null);
    const [openSMSDialog, setOpenSMSDialog] = useState(false);
    const [isVerifierInitialized, setIsVerifierInitialized] = useState(false);
    const recaptchaContainerRef = useRef<HTMLDivElement>(null);
    const [isRecaptchaSolved, setIsRecaptchaSolved] = useState(false);
    const [verifier, setVerifier] = useState<RecaptchaVerifier | undefined>(
        undefined
    );

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (openSMSDialog && !isVerifierInitialized) {
            timeoutId = setTimeout(() => {
                try {
                    const newVerifier = new RecaptchaVerifier(
                        auth,
                        "recaptcha-container",
                        {
                            callback: function (value: any) {
                                setIsRecaptchaSolved(true);
                            },
                            "expired-callback": function (value: any) {
                                setIsRecaptchaSolved(false);
                            },
                        }
                    );

                    newVerifier.render().then(() => {
                        setIsVerifierInitialized(true);
                        setVerifier(newVerifier);
                    });
                } catch (error: any) {
                    setErrorMessage(error.message);
                    setOpen(true);
                }
            }, 50);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [openSMSDialog]);

    const handleCloseSnackbar = (
        event: React.SyntheticEvent | Event,
        reason: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleCloseAlert = (event: React.SyntheticEvent<Element, Event>) => {
        setOpen(false);
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await login(email, password);
            navigate("/settings");
        } catch (error: any) {
            setErrorMessage(error.message);
            setOpen(true);
        }
    };

    const handleGoogleLogin = async () => {
        try {
            await googleLogin();
            navigate("/settings");
        } catch (error: any) {
            setErrorMessage(error.message);
            setOpen(true);
        }
    };

    const handlePhoneLogin = async () => {
        const verifierInstance = verifier;

        if (!isVerifierInitialized || !verifierInstance) {
            setErrorMessage("reCAPTCHA verifier is not initialized.");
            setOpen(true);
            return;
        }

        try {
            const result = await phoneLogin(phoneNumber, verifierInstance);
            setConfirmationResult(result);
        } catch (error: any) {
            setErrorMessage(error.message);
            setOpen(true);
            verifierInstance.clear();
            setIsVerifierInitialized(false);
        }
    };

    const handleConfirmCode = async () => {
        if (confirmationResult) {
            try {
                await confirmationResult.confirm(confirmationCode);
                navigate("/settings");
            } catch (error: any) {
                setErrorMessage(error.message);
                setOpen(true);
            }
        }
    };

    const handleForgotPassword = async () => {
        try {
            await sendResetEmail(email);
            setErrorMessage("パスワードリセットのメールを送信しました");
            setOpen(true);
        } catch (error: any) {
            setErrorMessage(error.message);
            setOpen(true);
        }
    };

    const handleOpenSMSDialog = () => {
        setOpenSMSDialog(true);
    };

    const handleCloseSMSDialog = () => {
        setOpenSMSDialog(false);
    };

    const handlePhoneNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const rawValue = e.target.value;
        const maskedValue = maskPhoneNumber(rawValue);
        setPhoneNumber(maskedValue);
    };

    return (
        <Container component="main" style={{ height: "100vh" }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "10vh",
                }}
            >
                <img
                    src={logo}
                    alt="BeBuddy Logo"
                    style={{
                        width: 300,
                        height: "auto",
                    }}
                />
            </div>
            <Grid
                container
                justifyContent="center"
                style={{ height: "90vh", alignItems: "center" }}
            >
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <form onSubmit={handleLogin}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="メール"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            name="password"
                            label="パスワード"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={loginWithButton}
                        >
                            ログイン
                        </Button>
                    </form>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleGoogleLogin}
                        sx={loginWithGoogleButton}
                    >
                        Google ログイン
                    </Button>
                    {/* <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={handleOpenSMSDialog}
            sx={{
              marginTop: "16px",
              "&:hover": {
                backgroundColor: "lightgray",
                color: "white",
              },
            }}
          >
            SMS ログイン
          </Button> */}
                    {/* <Dialog
                        open={openSMSDialog}
                        onClose={handleCloseSMSDialog}
                        aria-labelledby="sms-dialog-title"
                    >
                        <DialogTitle id="sms-dialog-title">
                            SMS Login
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                type="tel"
                                placeholder="携帯番号を入力してください"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "desiredHeight",
                                    marginBottom: "20px",
                                }}
                            >
                                <div
                                    id="recaptcha-container"
                                    ref={recaptchaContainerRef}
                                ></div>
                            </div>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handlePhoneLogin}
                                disabled={
                                    !isVerifierInitialized || !isRecaptchaSolved
                                }
                            >
                                コードを送る
                            </Button>
                            <TextField
                                value={confirmationCode}
                                onChange={(e) => {
                                    const value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                    );
                                    setConfirmationCode(value);
                                }}
                                inputProps={{
                                    maxLength: 6,
                                    pattern: "\\d{6}",
                                }}
                                placeholder="確認コードを入力してください"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleConfirmCode}
                            >
                                コードを確認する
                            </Button>
                        </DialogContent>
                    </Dialog> */}
                    <div style={{ textAlign: "center" }}>
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={handleForgotPassword}
                            sx={{
                                textTransform: "none",
                                fontSize: "1.1rem",
                                fontWeight: 500,
                            }}
                        >
                            パスワードを忘れた方
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity="error"
                    sx={{ width: "100%", fontSize: "1.5em", padding: "20px" }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Login;
