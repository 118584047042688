import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import OrgManagementRoot from "../components/Organizations/OrgManagementRoot";
import OrgForm from "../components/Organizations/OrgForm";
import { fetchUserOrganization } from "../helpers/fetchUserOrganization";

interface OrgManagementProps {
    handleLogoChange: (logo: string) => void;
}

const OrgManagement: React.FC<OrgManagementProps> = ({ handleLogoChange }) => {
    const { userRole, currentUser, organization } = useAuth();
    const [selectedOrganization, setSelectedOrganization] =
        useState<string>("");

    useEffect(() => {
        fetchUserOrganization(currentUser, setSelectedOrganization);
    }, [currentUser]);

    const renderOrgManagementComponents = () => {
        if (userRole === "root") {
            return (
                <>
                    <OrgManagementRoot />
                </>
            );
        } else if (userRole === "admin" || userRole === "user") {
            return (
                <>
                    <OrgForm
                        mode={"EDIT"}
                        uid={organization?.uid}
                        handleLogoChange={handleLogoChange}
                    />
                </>
            );
        }
    };

    return (
        <div>
            {/* <OrgInfo /> */}
            {renderOrgManagementComponents()}
        </div>
    );
};

export default OrgManagement;
