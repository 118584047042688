import React, { useState, useEffect } from "react";
import {
    Typography,
    Box,
    Button,
    Grid,
    TextField,
    Snackbar,
    SelectChangeEvent,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { db } from "../../services/firebaseService";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { Configuration } from "../../types/configurations/configurations";
import { SYSTEM_CONFIG_PATH } from "../../paths";

interface ConfigManagementRootProps {
    selectedOrganization: string | undefined;
}

const ConfigManagementAdmin: React.FC<ConfigManagementRootProps> = ({
    selectedOrganization,
}) => {
    const { currentUser, userRole } = useAuth();
    const [configData, setConfigData] = useState<Configuration | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [gridData, setGridData] = useState<Partial<Configuration>>({
        fetch_type: "",
        fetch_range_hours: 0,
        fetch_range_offset: 0,
        number_of_threads: 0,
        reaction_type: "",
        start_reaction: "",
        end_reaction: ""
    });

    useEffect(() => {
        const fetchConfiguration = async () => {
            setLoading(true);
            if (currentUser && selectedOrganization) {
                const selectedOrganizationConfigRef = doc(
                    db,
                    SYSTEM_CONFIG_PATH,
                    selectedOrganization
                );

                try {
                    const configDocSnap = await getDoc(
                        selectedOrganizationConfigRef
                    );
                    const configData = configDocSnap.data() as Configuration;

                    setConfigData(configData);
                    setGridData(configData);
                    setLoading(false);
                } catch (error) {
                    console.error("Error fetching config:", error);
                    setLoading(false);
                }
            }
        };

        fetchConfiguration();
    }, [selectedOrganization]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (
            name === "number_of_threads" ||
            name === "fetch_range_hours" ||
            name === "fetch_range_offset"
        ) {
            setGridData((prev) => ({
                ...prev,
                [name]: parseInt(value, 10),
            }));
        } else {
            // Update the state with the new formData
            setGridData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleFetchTypeChange = (
        event: SelectChangeEvent<string>,
        child: React.ReactNode
    ) => {    
        setGridData((prev) => ({ ...prev, ['fetch_type']: event.target.value }));
    };

    const updateConfigDocument = async (
        uid: string,
        gridData: Partial<Configuration>
    ) => {
        const configData: Partial<Configuration> = {
            fetch_type: gridData.fetch_type,
            fetch_range_hours: gridData.fetch_range_hours,
            fetch_range_offset: gridData.fetch_range_offset,
            number_of_threads: gridData.number_of_threads,
            reaction_type: gridData.reaction_type,
            start_reaction: gridData.start_reaction,
            end_reaction: gridData.end_reaction
        };

        try {
            const Timestamp = new Date();
            const configRef = doc(
                db,
                `${SYSTEM_CONFIG_PATH}/${uid}`
            );
            await updateDoc(configRef, {
                ...configData,
            });

            setSnackbarMessage("Succesfully updated the configuration!");
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleUpdateConfig = async () => {
        if (currentUser && selectedOrganization) {
            await updateConfigDocument(selectedOrganization, gridData);
        }
    };

    return (
        <Box p={2}>
            <Box my={2}>
                {selectedOrganization && !configData && (
                    <>
                        <Typography variant="subtitle1" gutterBottom>
                            システム設定が作成されていません
                        </Typography>
                    </>
                )}
                {selectedOrganization && configData && (
                    <>
                        <Typography variant="subtitle1" gutterBottom>
                            現在のシステム設定
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel id="fetch-type-input-label">
                                取得種別
                                </InputLabel>
                                <Select
                                    label="fetch_type"
                                    labelId="fetch-type-select-label"
                                    id="fetch-type-select"
                                    value={gridData.fetch_type}
                                    onChange={handleFetchTypeChange}
                                    disabled={(userRole !== 'admin') && (userRole !== 'root')}
                                >
                                    <MenuItem key="thread" value="thread">
                                    スレッド
                                    </MenuItem>
                                    <MenuItem key="start-end" value="start-end">
                                    開始-終了
                                    </MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    label="スレッド数"
                                    name="number_of_threads"
                                    value={gridData.number_of_threads}
                                    onChange={handleChange}
                                    disabled={(userRole !== 'admin') && (userRole !== 'root')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    label="フェッチ範囲時間"
                                    name="fetch_range_hours"
                                    value={gridData.fetch_range_hours}
                                    onChange={handleChange}
                                    disabled={(userRole !== 'admin') && (userRole !== 'root')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    label="データ集計期間（n時間分）"
                                    name="fetch_range_offset"
                                    value={gridData.fetch_range_offset}
                                    onChange={handleChange}
                                    disabled={(userRole !== 'admin') && (userRole !== 'root')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    label="リアクション（Slack）"
                                    name="reaction_type"
                                    value={gridData.reaction_type}
                                    onChange={handleChange}
                                    disabled={(userRole !== 'admin') && (userRole !== 'root')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    label="開始のリアクション (Slack)"
                                    name="start_reaction"
                                    value={gridData.start_reaction}
                                    onChange={handleChange}
                                    disabled={(userRole !== 'admin') && (userRole !== 'root')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    label="終了のリアクション (Slack)"
                                    name="end_reaction"
                                    value={gridData.end_reaction}
                                    onChange={handleChange}
                                    disabled={(userRole !== 'admin') && (userRole !== 'root')}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Box pr={1} component="span">
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleUpdateConfig}
                                disabled={(userRole !== 'admin') && (userRole !== 'root') || loading}
                            >
                                システム設定を更新{/* Update Configuration */}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            />
        </Box>
    );
};

export default ConfigManagementAdmin;
