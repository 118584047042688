import {
    Box,
    Button,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { getAuth } from "firebase/auth";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteUser } from "../../apis/UserApi";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../services/firebaseService";
import { User } from "../../types/users/users";
import { FIREBASE_AUTHENTICATORS_PATH, ORGANIZATIONS_PATH } from "../../paths";

interface UserManagementAdminProps {
    selectedCompany: string | undefined;
}

const UserManagementAdmin: React.FC<UserManagementAdminProps> = ({
    selectedCompany,
}) => {
    const { currentUser } = useAuth();
    const [usersData, setUsersData] = useState<User[]>([]);
    const [userCount, setUserCount] = useState<number>(0);
    const [maxUsers, setMaxUsers] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        if (!currentUser || !selectedCompany) {
            return; // Early return if currentUser or selectedCompany is falsy
        }

        const fetchData = async () => {
            setLoading(true);
            setUsersData([]); // Clear the existing data

            const usersRef = collection(
                db,
                FIREBASE_AUTHENTICATORS_PATH
            );
            const selectedCompanyRef = doc(
                db,
                ORGANIZATIONS_PATH,
                selectedCompany
            );
            const usersQuery = query(
                usersRef,
                where("organization_uid", "==", selectedCompanyRef)
            );

            try {
                const usersQuerySnapshot = await getDocs(usersQuery);
                const fetchedUsersData: User[] = [];

                for (const docSnap of usersQuerySnapshot.docs) {
                    const firebaseAuthenticatorData = docSnap.data();
                    if (firebaseAuthenticatorData.uid) {
                        const userDocSnap = await getDoc(
                            firebaseAuthenticatorData.uid
                        );
                        if (userDocSnap.exists()) {
                            const userData = userDocSnap.data() as User;
                            userData.user_uid = userDocSnap.id;
                            userData.organization_uid =
                                firebaseAuthenticatorData.organization_uid.id;
                            fetchedUsersData.push(userData);
                        }
                    }
                }

                setUsersData(fetchedUsersData);
                setUserCount(fetchedUsersData.length);
            } catch (error) {
                console.error("Error fetching users:", error);
            }

            try {
                const orgDocRef = doc(
                    db,
                    `${ORGANIZATIONS_PATH}/${selectedCompany}`
                );

                const orgDocSnapshot = await getDoc(orgDocRef);

                if (orgDocSnapshot.exists()) {
                    const orgData = orgDocSnapshot.data();
                    if (orgData && orgData.max_users) {
                        setMaxUsers(parseInt(orgData.max_users));
                    }
                }
            } catch (error) {
                console.error("Error fetching max users:", error);
            }

            setLoading(false);
        };

        fetchData();
    }, [currentUser, selectedCompany]);

    const handleEdit = (user: User) => {
        navigate(`/edit/${user.user_uid}`, {
            state: { organizationUid: user.organization_uid },
        });
    };

    const handleSignUpNewUser = () => {
        navigate("/signup", {
            state: { organizationUid: selectedCompany },
        });
    };

    const handleDelete = async (user: User) => {
        if (!window.confirm("このユーザを削除してもよろしいですか？")) {
            return;
        }

        try {
            const idToken = await getAuth().currentUser?.getIdToken(true);
            if (idToken) {
                await deleteUser(user.user_uid, idToken);
            }

            const updatedUsers = usersData.filter(
                (u) => u.user_uid !== user.user_uid
            );
            setUsersData(updatedUsers);
            setSnackbarMessage(`ユーザを正常に削除しました ${user.name}`);
            setUserCount(updatedUsers.length);
            setSnackbarOpen(true);
        } catch (error) {
            console.error("ユーザの削除中にエラーが発生しました：", error);
        }
    };

    return (
        <Box p={2}>
            <Box mb={2}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSignUpNewUser}
                    disabled={userCount === maxUsers || loading}
                >
                    ユーザ登録{/* Sign Up New User */}
                </Button>
            </Box>
            {userCount === maxUsers && (
                <Typography variant="body1" color="error">
                    最大ユーザ数に達しました。 {/* Maximum users reached. */}
                </Typography>
            )}
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>名前</TableCell> {/* Name */}
                            <TableCell>ロール</TableCell> {/* Role */}
                            <TableCell>作成日</TableCell> {/* Created At */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersData.map((user) => (
                            <TableRow key={user.user_uid}>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.role}</TableCell>
                                <TableCell>
                                    {user.created_at
                                        ?.toDate()
                                        .toLocaleDateString("ja-JP")}
                                </TableCell>
                                <TableCell align="right">
                                    <Box pr={1} component="span">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleEdit(user)}
                                        >
                                            編集 {/* Edit */}
                                        </Button>
                                    </Box>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => handleDelete(user)}
                                    >
                                        削除 {/* Delete */}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            />
        </Box>
    );
};

export default UserManagementAdmin;
