import {
    Box,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TextField,
} from "@mui/material";
import React, { useState } from "react";

interface RowDataType<T> {
    [key: string]: any;
}

interface PaginatedTableProps {
    tableData: RowDataType<[]>;
    searchFields: string[];
    renderRows: Function;
    renderHeadings: Function
}

interface Row {
    [key: string]: string;
}

const PaginatedTable: React.FC<PaginatedTableProps> = ({
    tableData,
    searchFields,
    renderRows,
    renderHeadings
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchTerm, setSearchTerm] = useState('');

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 25));
        setPage(0);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setPage(0); // Reset page when the search term changes
    };

    const isRowMatch = (row: Row, fieldsToSearch: string[], searchTerm: string) => {
        const includesSearchTerm = (value: string) => value.toLowerCase().includes(searchTerm.toLowerCase());
        return fieldsToSearch.some((field: string) => includesSearchTerm(row[field]));
    };

    const filteredData = tableData.filter((row: Row) =>
        isRowMatch(row, searchFields, searchTerm)
    );
    const slicedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return (
        <>
            <Box marginRight={0}>
                <TextField
                    label="検索"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </Box>
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table aria-label="Data">
                    <TableHead>{renderHeadings()}</TableHead>
                    <TableBody>{renderRows(slicedData)}</TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </>
    );
};

export default PaginatedTable;