// styles.js
const buttonStyles = {
    loginWithGoogleButton: {
      transition: "background-color 0.3s, box-shadow 0.3s",
      marginTop: "16px",
      padding: "12px 16px 12px 30px",
      border: "none",
      borderRadius: "15px",
      boxShadow: "0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25)",
      color: "black",
      fontSize: "20px",
      fontWeight: "bold",
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);", // Your SVG data
      backgroundColor: "white",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "10% 50%",
      "&:hover": {
        boxShadow: "0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25)",
      },
      "&:active": {
        backgroundColor: "#eeeeee",
      },
      "&:focus": {
        outline: "none",
        boxShadow: "0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc",
      },
      "&:disabled": {
        filter: "grayscale(100%)",
        backgroundColor: "#ebebeb",
        boxShadow: "0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25)",
        cursor: "not-allowed",
      },
    },
    loginWithButton: {
      transition: "background-color 0.3s, box-shadow 0.3s",
      marginTop: "16px",
      padding: "12px 16px 12px 30px",
      border: "none",
      borderRadius: "15px",
      boxShadow: "0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25)",
      color: "black",
      fontSize: "20px",
      fontWeight: "bold",
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      backgroundColor: "#f57c00", //"#71C9CE",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "10% 50%",
      "&:hover": {
        boxShadow: "0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25)",
      },
      "&:active": {
        backgroundColor: "#eeeeee",
      },
      "&:focus": {
        outline: "none",
        boxShadow: "0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc",
      },
      "&:disabled": {
        filter: "grayscale(100%)",
        backgroundColor: "#ebebeb",
        boxShadow: "0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25)",
        cursor: "not-allowed",
      },
    },
  };
  
  export default buttonStyles;
  