import { doc, getDoc } from "firebase/firestore";
import { db } from "../services/firebaseService";
import { User } from "firebase/auth";
import { SetStateAction } from "react";
import { FIREBASE_AUTHENTICATORS_PATH } from "../paths";

export const fetchUserOrganization = async (
    currentUser: User | null, 
    setSelectedOrganization: { (value: SetStateAction<string>): void; (arg0: any): void; }) => {
    if (!currentUser) {
        console.error("No current user.");
        return;
    }

    const authRef = doc(
        db,
        `${FIREBASE_AUTHENTICATORS_PATH}/${currentUser.uid}`
    );
    const authSnap = await getDoc(authRef);

    const organizationId = authSnap.data()?.organization_uid?.id;
    if (organizationId) {
        setSelectedOrganization(organizationId);
    } else {
        console.error(
            "No organization_uid found in the auth document."
        );
    }
};