import React, { useEffect, useState } from "react";
import SecretManagementAdmin from "../components/Secrets/SecretManagementAdmin";
import { useAuth } from "../contexts/AuthContext";
import { fetchUserOrganization } from "../helpers/fetchUserOrganization";
import OrganizationSelect from "../components/OrganizationSelect/OrganizationSelect";

type SecretManagementProps = {
    uid?: string | undefined;
};

const SecretManagement: React.FC<SecretManagementProps> = ({ uid }) => {
    const { currentUser, userRole } = useAuth();
    const [selectedOrganization, setSelectedOrganization] = useState<string>(
        uid || ""
    );

    useEffect(() => {
        if (!uid) {
            fetchUserOrganization(currentUser, setSelectedOrganization);
        }
    }, [currentUser, uid]);

    const renderSecretManagementComponents = () => {
        if (userRole === "root") {
            return (
                <>
                    <OrganizationSelect
                        selectedOrganization={selectedOrganization}
                        setSelectedOrganization={setSelectedOrganization}
                    />
                    <SecretManagementAdmin
                        selectedOrganization={selectedOrganization}
                    />
                </>
            );
        } else if (userRole === "admin") {
            return (
                <SecretManagementAdmin
                    selectedOrganization={selectedOrganization}
                />
            );
        }
        return null;
    };

    return (
        <div>
            {renderSecretManagementComponents()}
        </div>
    );
};

export default SecretManagement;
