const baseTypography = {
    fontFamily: '"Lucida Sans", "Lucida Sans Regular", sans-serif',
    h1: {
        fontWeight: 700,
        fontSize: "3rem",
    },
    h2: {
        fontWeight: 700,
        fontSize: "2.5rem",
    },
    h3: {
        fontWeight: 700,
        fontSize: "2rem",
    },
    h4: {
        fontWeight: 700,
        fontSize: "1.75rem",
    },
    h5: {
        fontSize: "1.6rem",
    },
    h6: {
        fontWeight: 700,
        fontSize: "1.25rem",
    },
    button: {
        fontWeight: 700,
        fontSize: "1.25rem",
    },
    subtitle1: {
        fontSize: "1rem",
        color: "#757575",
    },
};

export const baseThemeProps =
{
    typography: baseTypography,
    shape: {
        borderRadius: 0,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: "transparent",
                        color: "#757575",
                    },
                },
            },
            defaultProps: {
                disableElevation: true,
                variant: "outlined", // Setting the default variant to "outlined"
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& input:-webkit-autofill": {
                        "-webkit-text-fill-color": "#f57c00", // Change the text color
                        "-webkit-box-shadow": "0 0 0 100px #121212 inset", // Change the background color
                    },
                },
            },
            defaultProps: {
                variant: "outlined", // Setting the default variant to "outlined"
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    width: "50%", // Set the width to 50%
                },
            },
        },
    },
};
