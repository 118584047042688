import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { getDocs, limit, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createUser, getUser, updateUser } from "../../apis/UserApi";
import { useAuth } from "../../contexts/AuthContext";
import {
    auth,
    collection,
    db,
    doc,
    getDoc,
    updateDoc,
} from "../../services/firebaseService";
import { Organization } from "../../types/organizations/organizations";
import { User, UserFormProps, UserRole } from "../../types/users/users";
import { ORGANIZATIONS_PATH, USERS_PATH } from "../../paths";

const UserForm: React.FC<UserFormProps> = ({ mode, uid }) => {
    const [formData, setFormData] = useState<Partial<User>>({
        name: "",
        role: "user",
        email: "",
        password: "",
        confirmPassword: "",
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [resetPasswordSnackbarOpen, setResetPasswordSnackbarOpen] =
        useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const { currentUser, userRole } = useAuth();
    const [formErrors, setFormErrors] = useState<Partial<User>>({});

    const location = useLocation();
    const passedOrgUid = location.state?.organizationUid;
    const [companyName, setCompanyName] = useState<string | null>(null);
    const [userDisabled, setUserDisabled] = useState(false);
    const [userAuthData, setUserAuthData] = useState<Partial<User>>({});
    const [userAuthDataRecieved, setUserAuthDataRecieved] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            const organizationUid = passedOrgUid;

            if (organizationUid) {
                const orgDocRef = doc(db, ORGANIZATIONS_PATH, organizationUid);
                const orgDocSnapshot = await getDoc(orgDocRef);
                if (orgDocSnapshot.exists()) {
                    const orgData = orgDocSnapshot.data() as Organization;
                    setCompanyName(orgData.name);
                }
            }

            if (uid) {
                const userDocRef = doc(db, USERS_PATH, uid);
                const userDocSnapshot = await getDoc(userDocRef);

                if (userDocSnapshot.exists()) {
                    const userData = userDocSnapshot.data() as User;
                    setFormData(userData);

                    try {
                        const idToken = await getAuth().currentUser?.getIdToken(
                            true
                        );

                        if (!idToken) {
                            console.error(
                                "Failed to retrieve the user ID token."
                            );
                            return;
                        }

                        const userAuthData = await getUser(uid, idToken);

                        if (!userAuthData) {
                            console.error(
                                "User authentication data not found."
                            );
                            return;
                        }

                        const { email, disabled } = userAuthData;

                        if (!email) {
                            console.error(
                                "Email not found in user authentication data."
                            );
                            return;
                        }

                        setFormData((prevUserData) => ({
                            ...prevUserData,
                            email: email,
                        }));

                        setUserDisabled(disabled);
                        setUserAuthData(userAuthData);
                        setUserAuthDataRecieved(true);
                    } catch (error) {
                        console.error(
                            "An error occurred while processing user authentication data:",
                            error
                        );
                    }
                }
            }
        };

        fetchUserData();
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (e: SelectChangeEvent<UserRole>) => {
        const name = e.target.name;
        const value = e.target.value as "admin" | "root";

        if (name) {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleSelectChangeIsActive = (e: SelectChangeEvent<string>) => {
        setUserDisabled(e.target.value === "true");
    };

    const handlePasswordResetConfirmation = () => {
        handlePasswordReset();
        setResetPasswordSnackbarOpen(false);
    };

    const handlePasswordReset = async () => {
        if (!currentUser?.email) return;

        try {
            await sendPasswordResetEmail(auth, userAuthData.email!);
            setSnackbarMessage(
                "パスワードリセットメールが正常に送信されました。"
            ); // Set the message for the Snackbar
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Error sending password reset email:", error);
            setSnackbarMessage(
                "パスワードリセットメールの送信中にエラーが発生しました。"
            ); // Set an error message for the Snackbar
            setSnackbarOpen(true);
        }
    };

    //TO-DO implement comprehensive validation layer
    const validateForm = (): boolean => {
        let errors: Partial<User> = {};
        let formIsValid = true;

        if (!formData.email) {
            formIsValid = false;
            errors.email = "メールアドレスは必須です"; // Email is required
        }

        if (mode === "REGISTER") {
            if (!formData.password) {
                formIsValid = false;
                errors.password = "パスワードは必須です"; // Password is required
            } else if (formData.password !== formData.confirmPassword) {
                formIsValid = false;
                errors.confirmPassword = "パスワードが一致しません"; // Passwords do not match
            }
        }

        if (!formData.name) {
            formIsValid = false;
            errors.name = "名前は必須です"; // Name is required
        }

        setFormErrors(errors);
        return formIsValid;
    };

    // Function to retrieve the largest number and increment it
    const getAndIncrementLargestNumber = async () => {
        try {
            // Reference to your Firestore collection
            const customersCollection = collection(db, "customers");
            const q = query(
                customersCollection,
                orderBy("no", "desc"),
                limit(1)
            );
            const querySnapshot = await getDocs(q);

            let largestNumber = 0;

            querySnapshot.forEach((doc) => {
                const customerData = doc.data();
                const customerNo = parseInt(customerData.no, 10);
                if (customerNo > largestNumber) {
                    largestNumber = customerNo;
                }
            });

            // Increment the largest number by 1
            const incrementedNumber = largestNumber + 1;

            // Update the Firestore document with the new incremented number
            const largestNumberDoc = doc(
                customersCollection,
                "yourCustomerDocumentId"
            );
            await updateDoc(largestNumberDoc, {
                no: String(incrementedNumber).padStart(3, "0"),
            });

            return incrementedNumber;
        } catch (error) {
            console.error("Error:", error);
            return null;
        }
    };

    const handleFirebaseSubmit = async (data: Partial<User>) => {
        try {
            if (mode === "REGISTER") {
                if (!validateForm()) {
                    return;
                }

                if (!currentUser || !currentUser.email) {
                    console.error("Current user or user email not found");
                    return;
                }

                //some firestore rules are missing for this operation
                //const incrementedNumber = await getAndIncrementLargestNumber();
                const userData: Partial<User> = {
                    name: formData.name,
                    role: formData.role,
                    password: formData.password,
                    email: formData.email,
                    modified_by: currentUser?.email,
                    //no: String(incrementedNumber).padStart(3, "0"),
                    no: "001",
                    organization_uid: passedOrgUid,
                };

                await getAuth()
                    .currentUser?.getIdToken(true)
                    .then(async function (idToken) {
                        await createUser(userData, idToken);
                    })
                    .catch(function (error) {
                        console.error(error);
                    });

                setSnackbarMessage("Successfully created new user!");
                setSnackbarOpen(true);
                navigate("/user-management");
            } else if (mode === "EDIT") {
                if (!currentUser || !currentUser.email) {
                    console.error("Current user or user email not found");
                    return;
                }

                if (!uid) {
                    console.error("No user selected");
                    return;
                }

                const userData: Partial<User> = {
                    name: formData.name,
                    role: formData.role,
                    modified_by: currentUser?.email,
                    disabled: userDisabled,
                };

                await getAuth()
                    .currentUser?.getIdToken(true)
                    .then(async function (idToken) {
                        await updateUser(uid, userData, idToken);
                    })
                    .catch(function (error) {
                        console.error(error);
                    });

                setSnackbarMessage("Successfully updated the user!");
                setSnackbarOpen(true);
                navigate(`/user-management/${passedOrgUid}`);
            }
        } catch (error: any) {
            console.error("Error processing user data:", error);
            setSnackbarMessage("Error: " + error.message);
            setSnackbarOpen(true);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleFirebaseSubmit(formData);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h5">
                    {mode === "EDIT"
                        ? `Edit User in ${companyName}`
                        : `Registering User for ${companyName}`}
                </Typography>

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 3 }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} container alignItems="center">
                            <TextField
                                required
                                fullWidth
                                label="メールアドレス"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                error={Boolean(formErrors.email)}
                                helperText={formErrors.email}
                                variant="outlined"
                                style={{ flex: 1, marginRight: "5px" }} // Flex property to fill remaining space
                                disabled={mode === 'EDIT'}
                            />
                            {!userAuthDataRecieved && mode === 'EDIT' ? (
                                <Typography
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    Loading...
                                </Typography>
                            ) : (
                                <Typography
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <CheckIcon /> Loaded
                                </Typography>
                            )}
                        </Grid>
                        {(userRole === "root" || userRole === "admin") &&
                            mode === "REGISTER" && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label={
                                                mode === "REGISTER"
                                                    ? "仮パスワード"
                                                    : "新しいパスワード"
                                            }
                                            name="password"
                                            type="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            error={Boolean(formErrors.password)}
                                            helperText={formErrors.password}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label={
                                                mode === "REGISTER"
                                                    ? "仮パスワード確認"
                                                    : "新しいパスワード確認"
                                            }
                                            name="confirmPassword"
                                            type="password"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                            error={Boolean(
                                                formErrors.confirmPassword
                                            )}
                                            helperText={
                                                formErrors.confirmPassword
                                            }
                                        />
                                    </Grid>
                                </>
                            )}
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                    setResetPasswordSnackbarOpen(true)
                                }
                                disabled={!userAuthDataRecieved}
                            >
                                パスワードをリセット
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label="名前"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                error={Boolean(formErrors.name)}
                                helperText={formErrors.name}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="role-select-label">
                                    ロール
                                </InputLabel>
                                <Select
                                    labelId="role-select-label"
                                    id="role-select"
                                    value={formData.role as UserRole}
                                    name="role"
                                    onChange={handleSelectChange}
                                    label="ロール"
                                    disabled={
                                        userRole === "user" ||
                                        (userRole === "admin" &&
                                            formData.role === "root")
                                    }
                                >
                                    <MenuItem value="user">一般</MenuItem>
                                    {(userRole === "root" ||
                                        userRole === "admin") && (
                                        <MenuItem value="admin">
                                            管理者
                                        </MenuItem>
                                    )}
                                    {(userRole === "root" ||
                                        (userRole === "admin" &&
                                            formData.role === "root")) && (
                                        <MenuItem value="root">
                                            システム管理者
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} container alignItems="center">
                            <Grid item xs={10}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="status-select-label">
                                        有効可否
                                    </InputLabel>
                                    <Select
                                        labelId="status-select-label"
                                        value={userDisabled ? "true" : "false"}
                                        onChange={handleSelectChangeIsActive}
                                        label="有効可否"
                                        id="status-select"
                                        disabled={userRole === "user"}
                                        // values are flipped for the consistency of ui
                                    >
                                        <MenuItem value={"true"}>無効</MenuItem>
                                        <MenuItem value={"false"}>
                                            有効
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                {!userAuthDataRecieved && mode === "EDIT" ? (
                                    <Typography
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                            position: "relative",
                                            right: "9px",
                                        }}
                                    >
                                        Loading...
                                    </Typography>
                                ) : (
                                    <Typography
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                            position: "relative",
                                            right: "9rem",
                                        }}
                                    >
                                        <CheckIcon /> Loaded
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="outlined"
                                color="primary"
                                disabled={!userAuthDataRecieved && mode === 'EDIT'}
                            >
                                {mode === "EDIT" ? "更新" : "登録"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Snackbar
                open={resetPasswordSnackbarOpen}
                onClose={() => setResetPasswordSnackbarOpen(false)}
                message="パスワードをリセットしますか？"
                action={
                    <>
                        <Button
                            color="inherit"
                            onClick={handlePasswordResetConfirmation}
                        >
                            確認
                        </Button>
                        <IconButton
                            size="small"
                            color="inherit"
                            onClick={() => setResetPasswordSnackbarOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                }
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                action={
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={() => setSnackbarOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            />
        </Container>
    );
};

export default UserForm;
